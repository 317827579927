import { Checkbox } from "@nextui-org/react";
import React from "react";
import Input from "../ui/Input";

const rolesPermissions = [
  "billing",
  "invoice",
  "notification",
  "packages",
  "payment",
  "plan",
  "product",
  "reason",
  "reports",
  "role",
  "staff",
  "store",
  "user",
  "vendor",
];

const EditModalRoleContent = ({ roleData, name, setName, permissions, setPermissions }) => {

  const togglePermission = (page, action, isSelected) => {
    setPermissions((prevState) => {
      if (isSelected) {
        return [...new Set([...prevState, `${page} ${action}`])]
      }
      else {
        return prevState.splice(prevState.indexOf(`${page} ${action}`), 1)
      }
    });
  };



  return (
    <div className="flex flex-col w-full gap-4 pr-1">
      <Input
        title="Role Name"
        type="text"
        name="roleName"
        placeholder="Role Name"
        value={roleData.attribute.name}
        onChange={(e) => setName(e.target.value)}
      />

      <div>
        <h3 className="text-[22px] font-semibold leading-6 text-[#58626C] capitalize py-5">
          Role Permissions
        </h3>

        {rolesPermissions.map((page, index) => (
          <div
            key={index}
            className="flex items-center justify-between gap-3 py-4 max-md:flex-col"
          >
            <p className="text-[15px] leading-[22px] text-[#4B465C] capitalize">
              {page}
            </p>
            <div className="flex items-center gap-[30px]">
              {["view", "edit", "create", "delete"].map((action) => (
                <Checkbox
                  value={() => permissions.includes(`${page} ${action}`)}
                  key={action}
                  className="w-full"
                  color="default"
                  classNames={{
                    wrapper: [
                      "group-data-[selected=true]:after:bg-primary-500",
                    ],
                    icon: ["group-data-[selected=true]:text-white"],
                    label: "capitalize text-[#4B465C] leading-[22px]",
                  }}
                  onValueChange={(isSelected) => togglePermission(page, action, isSelected)}
                >
                  {action}
                </Checkbox>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EditModalRoleContent

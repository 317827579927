import React from 'react';

const UsersIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <ellipse
                cx='8.24967'
                cy='6.41667'
                rx='3.66667'
                ry='3.66667'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='8.24967'
                cy='6.41667'
                rx='3.66667'
                ry='3.66667'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.75 19.25V17.4167C2.75 15.3916 4.39162 13.75 6.41667 13.75H10.0833C12.1084 13.75 13.75 15.3916 13.75 17.4167V19.25'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.75 19.25V17.4167C2.75 15.3916 4.39162 13.75 6.41667 13.75H10.0833C12.1084 13.75 13.75 15.3916 13.75 17.4167V19.25'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.667 2.86914C16.2894 3.28455 17.4242 4.74647 17.4242 6.42122C17.4242 8.09598 16.2894 9.5579 14.667 9.97331'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.667 2.86914C16.2894 3.28455 17.4242 4.74647 17.4242 6.42122C17.4242 8.09598 16.2894 9.5579 14.667 9.97331'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.25 19.2497V17.4164C19.2404 15.7522 18.1113 14.3032 16.5 13.8872'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.25 19.2497V17.4164C19.2404 15.7522 18.1113 14.3032 16.5 13.8872'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default UsersIcon;

import React, { useEffect, useState } from 'react';
import { Button } from "@nextui-org/react";
import CalenderBtn from "../ui/icons/CalenderBtn";
import master from "../../assets/images/mastercard 1.png";
import Modal from '../ui/Modal';
import ConfirmPaymentsModal from './ConfirmPaymentsModal'
import { deleteBilling, getBilling } from '../../api/AppManagement';
import Loader from '../ui/Loader';

const Billing = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [billing, setBilling] = useState();

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    const getBillingData = async () => {
      const { data } = await getBilling()
      setLoading(false)
      setBilling(data)
      console.log(data)
    }
    getBillingData()
  }, [])

  const deleteHandler = async (id) => {
    await deleteBilling(id)
  }

  return (
    <div className="flex flex-col gap-[30px] p-6 bg-white rounded-md shadow-[0px_4px_18px_0px_rgba(75, 70, 92, 0.10)]">
      {loading ? <Loader /> : <><div className="flex flex-col gap-4">
        <p className="text-lg font-semibold capitalize">Payments</p>
        <div className="flex items-start justify-center h-full gap-4 p-4 rounded-md border border-[#DBDADE]">
          <div className="w-full flex flex-col items-start gap-[10px]">
            <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
              {billing?.data?.data?.attribute?.total || 130}
            </p>
            <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
              not done
            </p>
            <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
              April, 2023 subscribe
            </p>
            <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
              done at 12/26/2023 12:00 pm
            </p>
          </div>
          <div className="w-full flex flex-col justify-between items-end h-[118px]">
            <Button
              size="sm"
              className="bg-primary-500 text-white text-sm rounded-md py-[9px] px-[19px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)]"
              onClick={openModal}
            >
              Pay Now
            </Button>
            <p className="text-xs text-[#4B465C] capitalize">pay_id: 2500</p>
          </div>
        </div>
      </div>
        <div className="flex flex-col items-start gap-4">
          <div className="w-full flex items-center justify-between">
            <p className="text-lg font-semibold capitalize">Payment History</p>
            <Button
              isIconOnly
              size="sm"
              className="bg-primary-500 text-white text-sm rounded-md shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)]"
            >
              <CalenderBtn />
            </Button>
          </div>

          <div className="w-full flex items-start justify-center gap-4 rounded-md p-4 border border-[#DBDADE]">
            <div className="w-full flex flex-col items-start gap-[10px]">
              <div className="flex gap-[10px]">
                <img
                  src={master}
                  alt="mastercard"
                  className="h-[26px] w-[42px]"
                />
                <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
                  110 EGP
                </p>
              </div>
              <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
                Paid Done
              </p>
              <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
                April, 2023 subscribe
              </p>
            </div>
            <div className="w-full flex flex-col justify-between items-end h-[90px]">
              <Button
                size="sm"
                className="bg-secondary-200 text-secondary-500 text-sm rounded-md py-[9px] px-[19px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)]"
                onClick={deleteHandler}
              >
                Delete
              </Button>
              <p className="text-xs text-[#4B465C] capitalize">pay_id: 2500</p>
              <p className="text-xs text-[#4B465C] capitalize">
                done at 12/26/2023 12:00 pm
              </p>
            </div>
          </div>

          <div className="w-full flex items-start justify-center gap-4 rounded-md p-4 border border-[#DBDADE]">
            <div className="w-full flex flex-col items-start gap-[10px]">
              <div className="flex gap-[10px]">
                <p className="text-[15px] font-semibold text-[#4B465C] capitalize leading-[21px]">
                  Cash
                </p>
                <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
                  110 EGP
                </p>
              </div>
              <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
                Paid Done
              </p>
              <p className="text-xs text-[#4B465C] capitalize leading-[22px]">
                April, 2023 subscribe
              </p>
            </div>
            <div className="w-full flex flex-col justify-between items-end h-[90px]">
              <Button
                size="sm"
                className="bg-secondary-200 text-secondary-500 text-sm rounded-md py-[9px] px-[19px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)]"
              >
                Delete
              </Button>
              <p className="text-xs text-[#4B465C] capitalize">pay_id: 2500</p>
              <p className="text-xs text-[#4B465C] capitalize">
                done at 12/26/2023 12:00 pm
              </p>
            </div>
          </div>
        </div></>}
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Confirm Payments">
        <ConfirmPaymentsModal closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default Billing;

import { Avatar, Button, Chip, Divider } from "@nextui-org/react";
import React, { useState } from "react";
import Modal from "../ui/Modal";
import EditStoreModal from "../stores/EditStoreModal";

const UserCard = ({ store, onUpdateStore }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const openEditModal = (store) => {
    setSelectedStore(store);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedStore(null);
  };

  return (
    <>
      <div className="lg:w-[360px] shadow-gray-sm">
        <div className="p-[24px] rounded-md bg-white">
          <div className="pt-[30px] flex flex-col gap-[16px] items-center justify-center">
            <Avatar size="lg" src={store.attributes.image} />
            <h3 className="text-medium text-[#4B465C]">
              {store.attributes.name}
            </h3>
            <Chip
              radius="sm"
              className="p-[5px 10px] bg-secondary-300 text-secondary-500 font-semibold"
            >
              STORE
            </Chip>
          </div>

          <Divider className="my-4" />

          <div className="flex flex-col gap-4 items-start justify-center">
            <p className="text-[#4B465C] text-xs leading-[22px]">Details</p>

            <div className="flex flex-col gap-3">
              <div className="flex gap-2">
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  ID:
                </p>
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  {store.id}
                </p>
              </div>

              <div className="flex gap-2">
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  Username:
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px]">
                  {store.attributes.name}
                </p>
              </div>

              <div className="flex gap-2">
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  Email:
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px]">
                  {store.attributes.email}
                </p>
              </div>

              <div className="flex gap-2">
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  Status:
                </p>
                <Chip
                  radius="sm"
                  className={`p-[5px 10px] text-sm font-semibold ${
                    store.attributes.status === 1
                      ? "bg-success-200 text-success-500"
                      : "bg-gray-200 text-gray-500"
                  }`}
                >
                  {store.attributes.status === 1 ? "Active" : "Inactive"}
                </Chip>
              </div>

              <div className="flex gap-2">
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  Plan:
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px]">Basic</p>
              </div>

              <div className="flex gap-2">
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  Contact:
                </p>
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  {store.attributes.contact}
                </p>
              </div>

              <div className="flex gap-2">
                <p className="text-[#4B465C] text-xs font-semibold leading-[22px] capitalize">
                  Address:
                </p>
                <p className="text-[#4B465C] text-xs leading-[22px]">
                  {store.attributes.address}
                </p>
              </div>
            </div>

            <div className="flex gap-4 w-full justify-center">
              <Button
                className="bg-primary-500  text-white text-sm rounded-md"
                onClick={() => openEditModal(store)}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title="Edit Store"
      >
        {selectedStore && (
          <EditStoreModal
            closeModal={closeEditModal}
            storeData={selectedStore}
            onUpdateStore={onUpdateStore}
          />
        )}
      </Modal>
    </>
  );
};

export default UserCard;

import React, { useState, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  DropdownItem,
} from "@nextui-org/react";
import mail from "../../assets/icons/mail.svg";
import eye from "../../assets/icons/eye.svg";
import dots from "../../assets/icons/dots-vertical.svg";
import DropdownMenus from "../ui/DropdownMenus";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import { deleteInvoice } from "../../api/DashboardManagement";
import { formatDate } from "../../helpers/format-date";
import { useNavigate } from "react-router";

const columnsHeaders = [
  { id: "id", name: "ID", sortable: true },
  { id: "store_name", name: "Store Name", sortable: true },
  { id: "package_name", name: "Package Name", sortable: true },
  { id: "total", name: "Total", sortable: true },
  { id: "issued_date", name: "Issued Date", sortable: true },
  { id: "balance", name: "Balance", sortable: true },
  { id: "actions", name: "Actions", sortable: false },
];

const InvoiceSingleTable = ({ data, onDeleteInvoice, onUpdateInvoice }) => {
  const navigate = useNavigate();

  const [sortDescriptor, setSortDescriptor] = useState({
    column: "store",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...data].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, data]);

  const deleteInvoiceHandler = async (id) => {
    await deleteInvoice(id);
    onDeleteInvoice(id);
  };

  return (
    <Table
      aria-label="stores_table"
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      classNames={{
        base: "overflow-x-auto",
        wrapper: "rounded-[0] px-0 py-0",
        th: "uppercase bg-transparent px-6 py-4 border-b",
        td: "py-8 px-6",
      }}
    >
      <TableHeader columns={columnsHeaders}>
        {(column) => (
          <TableColumn key={column.id} allowsSorting={column.sortable}>
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody items={sortedItems} emptyContent={"No stores found"}>
        {(item) => (
          <TableRow key={item.id} className="border-b">
            <TableCell>
              <p className="text-[12px] leading-[22px] text-primary-500">
                #{item.id}
              </p>
            </TableCell>
            <TableCell>
              <div className="flex items-center gap-[10px]">
                <img
                  src={item.vendor.image}
                  alt="user"
                  className="w-[38px] h-[38px] rounded-[50%] object-cover"
                />
                <div className="flex flex-col">
                  <span className="text-[15px] leading-[22px] font-semibold capitalize">
                    {item.vendor.name}
                  </span>
                  <span className="text-[13px] leading-5 text-gray-400 capitalize">
                    {item.vendor.email}
                  </span>
                </div>
              </div>
            </TableCell>
            <TableCell>{item?.invoice_items[0]?.plan?.title}</TableCell>
            <TableCell>${item.attribute.total}</TableCell>
            <TableCell>{formatDate(item.attribute.created_at)}</TableCell>
            <TableCell>
              {item.attribute.balance === "paid" ? (
                <Chip
                  radius="sm"
                  className="p-[5px 10px] bg-success-200 text-success-500 text-sm font-semibold capitalize"
                >
                  {item.attribute.balance}
                </Chip>
              ) : (
                <p className="text-[13px] leading-5 text-gray-800">
                  -${item.attribute.balance}
                </p>
              )}
            </TableCell>
            <TableCell>
              <div className="flex gap-2 items-center w-fit">
                <img src={mail} alt="mail" className="cursor-pointer" />
                <img
                  src={eye}
                  alt="eye"
                  className="cursor-pointer"
                  onClick={() => navigate(`${item.id}`)}
                />
                <DropdownMenus>
                  <DropdownItem
                    key="edit"
                    startContent={<img src={edit} alt="dots" />}
                    onClick={() => navigate(`${item.id}?edit=true`)}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    key="delete"
                    className="text-danger"
                    startContent={
                      <img src={trash} alt="dots" className="text-danger" />
                    }
                    // onClick={onDelete}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenus>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default InvoiceSingleTable;

import { Button } from "@nextui-org/button";
import React, { useState } from "react";
import figure from "../../assets/images/illustration.png";
import Modal from "../ui/Modal";
import EditPermissionsModal from "./EditPermissionsModal";
import RoleModalContent from "./RoleModalContent";
import RoleTable from "./RoleTable";
import RolesCards from "./RolesCards";

const RolesAndPermissions = () => {
  const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [newRole, setNewRole] = useState();

  const openAddRoleModal = () => {
    setIsAddRoleModalOpen(true);
  };

  const closeAddRoleModal = () => {
    setIsAddRoleModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">
        Roles{" "}
        <span className="text-[#4B465C] text-[22px] leading-[30px] font-normal capitalize">
          & permissions
        </span>
      </h3>

      {/* <RolesCards openEditModal={openEditModal} /> */}

      <div className="w-full flex justify-center items-center bg-white rounded-md shadow-[0px_4px_18px_0px_rgba(75, 70, 92, 0.10)]">
        <img className="pl-[30px]" src={figure} alt="illustration" />
        <div className="w-full flex flex-col justify-center items-end gap-3 p-6">
          <Button
            size="sm"
            className="w-max bg-primary-500 text-white text-[15px] rounded-md py-[10px] px-[20px] font-medium tracking-[0.43px] capitalize"
            onClick={openAddRoleModal}
          >
            Add New Role
          </Button>
          <p className="text-[#4B465C] text-xs leading-[22px] font-normal">
            Add role, if it does not exist
          </p>
        </div>
      </div>

      <RoleTable openAddRoleModal={openAddRoleModal} newRole={newRole} />

      <Modal
        isOpen={isAddRoleModalOpen}
        onClose={closeAddRoleModal}
        title="Create Role"
      >
        <RoleModalContent
          closeModal={closeAddRoleModal}
          setNewRole={setNewRole}
        />
      </Modal>
    </section>
  );
};

export default RolesAndPermissions;

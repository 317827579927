import React from "react";
import { useParams } from "react-router-dom";
import StaffMember from "../components/staff-member/StaffMember";

const StaffMemberPage = () => {
  const { id } = useParams();

  return <StaffMember id={id} />;
};

export default StaffMemberPage;

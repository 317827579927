import React, { useState, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  DropdownItem,
} from "@nextui-org/react";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import dots from "../../assets/icons/dots-vertical.svg";
import eye from "../../assets/icons/eye.svg";
import status from '../../assets/icons/toggle-status.svg'
import { changeStatusProduct, deleteProduct } from "../../api/AppManagement";
import Modal from "../ui/Modal";
import EditProductModal from "./EditProductModal";
import { formatDate } from "../../helpers/format-date";
import avatar from "../../assets/images/userImg.png";
import DropdownMenus from "../ui/DropdownMenus";

const columnsHeaders = [
  { id: "id", name: "id", sortable: true },
  { id: "product details", name: "product details", sortable: true },
  { id: "category", name: "category", sortable: true },
  { id: "carat", name: "carat", sortable: true },
  { id: "count", name: "count", sortable: true },
  { id: "weight", name: "weight", sortable: true },
  { id: "store name", name: "store name", sortable: true },
  { id: "date added", name: "date added", sortable: true },
  { id: "status", name: "Status", sortable: true },
  { id: "actions", name: "actions", sortable: false },
];

const ProductsSingleTable = ({ data, onDeleteProduct, onUpdateProduct }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [sortDescriptor, setSortDescriptor] = useState({
    column: "product details",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...data].sort((a, b) => {
      let first;
      let second;
      let cmp;
      switch (sortDescriptor.column) {
        case "product details":
          first = a.attributes.title;
          second = b.attributes.title;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "date added":
          first = a.attributes["created_at"];
          second = b.attributes["created_at"];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "category":
          first = a.attributes.type;
          second = b.attributes.type;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "status":
          first = a.attributes.status;
          second = b.attributes.status;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "count":
          first = a.attributes.amount;
          second = b.attributes.amount;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "carat":
          first = a.attributes.standard;
          second = b.attributes.standard;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "weight":
          first = a.attributes.weight;
          second = b.attributes.weight;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "store name":
          first = a.vendor.name;
          second = b.vendor.name;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        default:
          first = a[sortDescriptor.column];
          second = b[sortDescriptor.column];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
      }
    });
  }, [data, sortDescriptor]);

  const deleteProductHandler = async (id) => {
    await deleteProduct(id);
    onDeleteProduct(id);
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const openEditModal = (product) => {
    setSelectedProduct(product);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedProduct(null);
    toggleEditing();
  };

  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus == 1 ? 0 : 1;
    const { data } = await changeStatusProduct(id, newStatus);
    onUpdateProduct(data.data);
  };

  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          wrapper: "rounded-[0] px-0 py-0",
          th:
            "uppercase bg-transparent px-6 py-4 border-b text-[#4B465C] font-semibold",
          td: "py-8 px-6 text-[#4B465C] font-semibold",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn key={column.id} allowsSorting={column.sortable}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.id} className="border-b">
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.attributes.title}</TableCell>
              <TableCell>{item.attributes.type}</TableCell>
              <TableCell>{item.attributes.standard}</TableCell>
              <TableCell>{item.attributes.amount}</TableCell>
              <TableCell>{item.attributes.weight}</TableCell>
              <TableCell>
                <div className="flex gap-[10px] w-[90%]">
                  <img
                    src={item.vendor.image ? item.vendor.image : avatar}
                    alt={item.vendor.name}
                    className="w-[38px] h-[38px] rounded-[50%]"
                  />
                  <div className="w-full truncate">
                    <p className="w-[90%] truncate">{item.vendor.name}</p>
                    <p className="text-[#4b465c61] text-[13px] w-[80%] truncate ">
                      {item.vendor.email}
                    </p>
                  </div>
                </div>
              </TableCell>
              <TableCell className="text-[10px]">
                {formatDate(item.attributes.created_at)}
              </TableCell>
              <TableCell>
                <Chip
                  radius="sm"
                  className={`p-[5px 10px] text-sm font-semibold capitalize ${
                    item.attributes.status == 1
                      ? "bg-success-200 text-success-500"
                      : "bg-secondary-200 text-secondary-500"
                  }`}
                >
                  {item.attributes.status == 1 ? "Active" : "Inactive"}
                </Chip>
              </TableCell>
              <TableCell>
                <div className="flex gap-2 items-center">
                  <img
                    src={edit}
                    alt="edit"
                    className="cursor-pointer"
                    onClick={() => {
                      openEditModal(item);
                      setIsEditing(true);
                    }}
                  />
                  <img
                    src={trash}
                    alt="trash"
                    className="cursor-pointer"
                    onClick={() => deleteProductHandler(item.id)}
                  />

                  <DropdownMenus>
                    <DropdownItem
                      key="preview"
                      startContent={<img src={eye} alt="preview" />}
                      onClick={() => {
                        openEditModal(item);
                        setIsEditing(false);
                      }}
                    >
                      Preview
                    </DropdownItem>
                    <DropdownItem
                      key="status"
                      startContent={
                        <img src={status} alt="toggle" />
                      }
                      onClick={() => {
                        toggleStatus(item.id, item.attributes.status);
                        console.log(item.id, item.attributes.status);
                      }}
                    >
                      Status
                    </DropdownItem>
                  </DropdownMenus>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title={isEditing ? "Edit Product" : "Preview Product"}
      >
        {selectedProduct && (
          <EditProductModal
            closeModal={closeEditModal}
            productData={selectedProduct}
            onUpdateProduct={onUpdateProduct}
            toggleEditing={toggleEditing}
            isEditing={isEditing}
          />
        )}
      </Modal>
    </>
  );
};

export default ProductsSingleTable;

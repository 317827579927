import React, { useState } from "react";
import merchantReport from "../../assets/icons/merchantReport.svg";
import reportPlus from "../../assets/icons/reportPlus.svg";
import reportMinus from "../../assets/icons/reportMinus.svg";
import Card from "./Card";
import ModalReport from "../store-reports/ModalReport";
import ExportModalContent from "./ExportModalContent";

const CardDetail = [
  {
    id: 1,
    icon: merchantReport,
    title: "Export Sales Report",
    subtitle:
      "For Any Durations You Can Just Select The Duration And The Store Name",
  },
  {
    id: 2,
    icon: reportPlus,
    title: "export purchases report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
  {
    id: 3,
    icon: reportMinus,
    title: "export sales and purchases report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
];

const Reports = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpen = () => {
    setIsModalOpen(true);
  };

  const onOpenChange = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="flex flex-col pt-[26px]">
      <h3 className="page-heading">Reports</h3>

      <div className="w-full flex justify-center items-center flex-col gap-[26px] my-[26px]">
        {CardDetail.map((item) => (
          <Card
            key={item.id}
            icon={item.icon}
            title={item.title}
            subtitle={item.subtitle}
            onOpen={onOpen}
          />
        ))}
      </div>

      <ModalReport isOpen={isModalOpen} onClose={onOpenChange}>
        <ExportModalContent
          onClose={onOpenChange}
          title="custom select"
        />
      </ModalReport>
    </section>
  );
};

export default Reports;

import React, { useState, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Avatar,
  DropdownItem,
} from "@nextui-org/react";
import mail from "../../assets/icons/mail.svg";
import eye from "../../assets/icons/eye.svg";
import dots from "../../assets/icons/dots-vertical.svg";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import status from '../../assets/icons/toggle-status.svg'
import avatar from "../../assets/images/userImg.png";
import { Link, useNavigate } from "react-router-dom";
import DropdownMenus from "../ui/DropdownMenus";
import { changeStatusStaff, deleteStaff } from "../../api/DashboardManagement";
const columnsHeaders = [
  { id: "user", name: "user", sortable: true },
  { id: "role", name: "role", sortable: true },
  { id: "status", name: "Status", sortable: true },
  { id: "actions", name: "Actions", sortable: false },
];

const StaffSingleTable = ({ staff, onDeleteStaff, onUpdateStaff }) => {
  const navigate = useNavigate();


  const [sortDescriptor, setSortDescriptor] = useState({
    column: "user",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...staff].sort((a, b) => {
      let first;
      let second;
      let cmp;
      switch (sortDescriptor.column) {
        case "user":
          first = a.attributes.title;
          second = b.attributes.title;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "role":
          first = a.attributes["created_at"];
          second = b.attributes["created_at"];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "status":
          first = a.attributes.status;
          second = b.attributes.status;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        default:
          first = a[sortDescriptor.column];
          second = b[sortDescriptor.column];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
      }
    });
  }, [staff, sortDescriptor]);

  const deleteStaffHandler = async (id) => {
    await deleteStaff(id);
    onDeleteStaff(id);
  };

  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus == 1 ? 0 : 1;
    const { data } = await changeStatusStaff(id, newStatus);
    onUpdateStaff(data.data);
  };

  return (
    <Table
      aria-label="members_table"
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      classNames={{
        wrapper: "rounded-[0] px-0 py-0",
        th: "uppercase bg-transparent px-6 py-4 border-b",
        td: "py-8 px-6",
      }}
    >
      <TableHeader columns={columnsHeaders}>
        {(column) => (
          <TableColumn key={column.id} allowsSorting={column.sortable}>
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody items={sortedItems} emptyContent={"No stores found"}>
        {(item) => (
          <TableRow key={item.id} className="border-b">
            <TableCell>
              <div className="flex gap-2 lg:w-[400px]">
                <Avatar src={item.attributes.image} />
                <div>
                  <p className="text-[#4B465C]">{item.attributes.name}</p>
                  <p className="text-[#4b465c9e]">{item.attributes.email}</p>
                </div>
              </div>
            </TableCell>
            <TableCell>{item.attributes.role}</TableCell>
            <TableCell>
              <Chip
                radius="sm"
                className={`p-[5px 10px] text-sm font-semibold capitalize ${item.attributes.status == 1
                  ? "bg-success-200 text-success-500"
                  : "bg-secondary-200 text-secondary-500"
                  }`}
              >
                {item.attributes.status == 1 ? "Active" : "Inactive"}
              </Chip>
            </TableCell>
            <TableCell>
              <div className="flex gap-2 items-center w-fit">
                <img src={mail} alt="mail" />
                {item && (
                  <Link to={{ pathname: `/staff/${item.id}`, state: { item } }}>
                    <img src={eye} alt="eye" className="min-w-[22px]" />
                  </Link>
                )}
                <DropdownMenus>
                  <DropdownItem
                    key="edit"
                    startContent={<img src={edit} alt="dots" />}
                    onClick={() => navigate(`/staff/${item.id}?edit=true`)}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    key="delete"
                    className="text-danger"
                    startContent={
                      <img src={trash} alt="dots" className="text-danger" />
                    }
                    onClick={() => deleteStaffHandler(item.id)}
                  >
                    Delete
                  </DropdownItem>
                  <DropdownItem
                    key="status"
                    startContent={
                      <img src={status} alt="toggle" />
                    }
                    onClick={() => {
                      toggleStatus(item.id, item.attributes.status);
                      console.log(item.id, item.attributes.status);
                    }}
                  >
                    Status
                  </DropdownItem>
                </DropdownMenus>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default StaffSingleTable;

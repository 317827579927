import { Button } from "@nextui-org/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import close from "../../assets/icons/close.svg";

const ModalRole = ({ isOpen, onClose, children, onAddRoleHandler, title, isEdit, updateRoleHandler }) => {


  const handleModalClick = (e) => {
    e.stopPropagation();
  };




  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 max-lg:px-4"
          onClick={onClose}
        >
          <motion.div
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? "0%" : "-100%" }}
            exit={{ opacity: 0, y: "-100%" }}
            transition={{ duration: 0.3 }}
            className="bg-white p-[65px] shadow-[0px_5px_20px_0px_rgba(75, 70, 92, 0.40)] rounded-md w-[720px] h-[80%] relative max-md:w-full max-md:p-[20px]  max-md:h-[70%]"
            onClick={handleModalClick}
          >
            <div className="flex justify-start items-center flex-col gap-3 mb-[26px] h-full ">
              <h2 className="text-[26px] font-semibold leading-9 text-[#4B465C] capitalize">
                {title}
              </h2>
              <button
                onClick={onClose}
                className="absolute top-0 right-0 translate-x-[50%]  translate-y-[-50%] p-[6px] rounded-md flex items-center justify-center bg-white cursor-pointer shadow-gray-sm"
              >
                <img src={close} alt="close" className="w-4 h-4" />
              </button>
              <p className="text-[12px] leading-[22px] text-[#282B3E] capitalize">
                Set Role permissions
              </p>

              <div className="w-full overflow-y-auto overflow-hidden scrollbar ">
                {children}
              </div>

              <div className="flex gap-4 w-full justify-center">
                {isEdit ? <Button className="bg-primary-500  text-white text-sm rounded-md max-md:mb-3"
                  onClick={updateRoleHandler}
                >
                  Save
                </Button> : <Button className="bg-primary-500  text-white text-sm rounded-md max-md:mb-3"
                  onClick={onAddRoleHandler}
                >
                  Submit
                </Button>}
                <Button
                  className="bg-secondary-200 text-[#A8AAAE] text-xs font-medium rounded-md"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ModalRole;

import React from "react";
import { Select, SelectItem } from "@nextui-org/react";
import clear from "../../assets/icons/clear.svg";

const InvoiceItem = ({ item, length, onDeleteItem, onUpdateItem }) => {
  return (
    <div className="w-full min-w-0">
      <div className="w-full h-full flex flex-col items-start justify-center gap-4">
        <div className="w-full px-4 flex items-start justify-between">
          <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
            Item
          </p>
          <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
            Cost
          </p>
          <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
            QTY
          </p>
          <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
            Price
          </p>
        </div>
        <div className="w-full min-w-0 h-full border rounded-md border-[#DBDADE]">
          <div className="w-full min-w-0 h-full flex items-start justify-between ">
            <div className="w-full min-w-0 flex flex-col items-start justify-center p-4 gap-6 border-r-1 overflow-auto scrollbar px-4">
              <div className="flex w-full min-w-0 items-center gap-4">
                <Select
                  placeholder={"Basic Package"}
                  variant="bordered"
                  size="small"
                  aria-label="Select item"
                  classNames={{
                    trigger:
                      "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
                  }}
                >
                  <SelectItem key={"plan"} value={"plan"}>
                    plan
                  </SelectItem>
                </Select>
                <input
                  type="number"
                  name="cost"
                  id="cost"
                  placeholder={"24"}
                  value={item.cost}
                  min={0}
                  onChange={(e) =>
                    onUpdateItem(item.id, e.target.name, e.target.value)
                  }
                  className="w-[130px] border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] font-normal bg-transparent text-[15px] leading-6 capitalize max-md:w-fit"
                />
                <input
                  type="number"
                  name="quantity"
                  id="quantity"
                  placeholder={"1"}
                  value={item.quantity}
                  min={1}
                  onChange={(e) =>
                    onUpdateItem(item.id, e.target.name, e.target.value)
                  }
                  className="w-[130px] border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] font-normal bg-transparent text-[15px] leading-6 capitalize max-md:w-fit"
                />
                <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                  EGP {item.price}
                </p>
              </div>
              <div className="w-fit flex items-start gap-6">
                <textarea
                  name="description"
                  id="description"
                  placeholder="Description"
                  value={item.description}
                  onChange={(e) =>
                    onUpdateItem(item.id, e.target.name, e.target.value)
                  }
                  className="w-full border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] font-normal text-[15px] leading-6 capitalize bg-transparent focus:outline-none resize-none"
                />
                <div>
                  <p className="w-full text-[#4B465C] text-xs leading-[22px] capitalize">
                    Discount : {item.discount}%
                  </p>
                  <input
                    id="discount"
                    name="discount"
                    type="number"
                    placeholder="%"
                    min={0}
                    maxLength={3}
                    value={item.discount}
                    onChange={(e) =>
                      onUpdateItem(item.id, e.target.name, e.target.value)
                    }
                    className="w-full border border-[#DBDADE] rounded-md text-[#4B465C] py-[7px] px-[14px] font-normal text-[15px] leading-6 capitalize bg-transparent focus:outline-none resize-none"
                  />
                </div>
              </div>
            </div>
            <button
              disabled={length <= 1}
              onClick={() => onDeleteItem(item.id)}
              className={`py-[14px] h-full px-[10px] flex flex-col justify-between items-center ${
                length <= 1 ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <img src={clear} alt="clear" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceItem;

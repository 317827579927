import React from 'react';

const PaymentsIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <g opacity='0.2'>
                <path
                    d='M2.0625 8.32733H19.9375V16.5C19.9375 16.6823 19.8651 16.8572 19.7361 16.9861C19.6072 17.1151 19.4323 17.1875 19.25 17.1875H2.75C2.56766 17.1875 2.3928 17.1151 2.26386 16.9861C2.13493 16.8572 2.0625 16.6823 2.0625 16.5V8.32733Z'
                    fill='#4B465C'
                />
                <path
                    d='M2.0625 8.32733H19.9375V16.5C19.9375 16.6823 19.8651 16.8572 19.7361 16.9861C19.6072 17.1151 19.4323 17.1875 19.25 17.1875H2.75C2.56766 17.1875 2.3928 17.1151 2.26386 16.9861C2.13493 16.8572 2.0625 16.6823 2.0625 16.5V8.32733Z'
                    fill='white'
                    fillOpacity='0.2'
                />
            </g>
            <path
                d='M14.4375 14.4375H17.1875M10.3125 14.4375H11.6875M2.0625 8.32734H19.9375M2.75 4.8125H19.25C19.6297 4.8125 19.9375 5.1203 19.9375 5.5V16.5C19.9375 16.8797 19.6297 17.1875 19.25 17.1875H2.75C2.3703 17.1875 2.0625 16.8797 2.0625 16.5V5.5C2.0625 5.1203 2.3703 4.8125 2.75 4.8125Z'
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.4375 14.4375H17.1875M10.3125 14.4375H11.6875M2.0625 8.32734H19.9375M2.75 4.8125H19.25C19.6297 4.8125 19.9375 5.1203 19.9375 5.5V16.5C19.9375 16.8797 19.6297 17.1875 19.25 17.1875H2.75C2.3703 17.1875 2.0625 16.8797 2.0625 16.5V5.5C2.0625 5.1203 2.3703 4.8125 2.75 4.8125Z'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default PaymentsIcon;

import React from "react";

const Input = ({
  title,
  type,
  name,
  placeholder,
  icon,
  className,
  value,
  onChange,
  disabled,
}) => {
  return (
    <div className="flex flex-col items-start w-full gap-1 ">
      <label className="text-[13px] capitalize font-normal text-[#4B465C]">
        {title}
      </label>
      <div
        className={`w-full flex  text-md border border-[#DBDADE] rounded-md text-black py-[7px] px-[14px]  ${className}`}
      >
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          className="w-full h-full focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
          value={value}
          onChange={(e) => (onChange ? onChange(e) : "")}
          disabled={disabled}
        />
        {icon && <img src={icon} alt={placeholder} className="icon" />}
      </div>
    </div>
  );
};

export default Input;

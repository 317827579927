import { Select, SelectItem } from "@nextui-org/react";
import React, { useState } from "react";
import pdf from "../../assets/icons/pdf.svg";
import print from "../../assets/icons/print.svg";
import { getExportSalesMonth } from "../../api/AppManagement";

const MonthlyModalContent = ({ title }) => {
  const months = [
    {
      value: 1,
      label: "January",
    },
    {
      value: 2,
      label: "February",
    },
    {
      value: 3,
      label: "March",
    },
    {
      value: 4,
      label: "April",
    },
    {
      value: 5,
      label: "May",
    },
    {
      value: 6,
      label: "June",
    },
    {
      value: 7,
      label: "July",
    },
    {
      value: 8,
      label: "August",
    },
    {
      value: 9,
      label: "September",
    },
    {
      value: 10,
      label: "October",
    },
    {
      value: 11,
      label: "November",
    },
    {
      value: 12,
      label: "December",
    },
  ];

  const [month, setMonth] = useState();
  const [loading, setLoading] = useState(false);

  const getExportSalesMonthData = async () => {
    setLoading(true);
    try {
      const { data } = await getExportSalesMonth(month);
      console.log(data);
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <p className="text-lg font-semibold leading-6 text-[#58626C] capitalize">
        {title}
      </p>
      <div className="flex flex-col gap-1 items-start w-full">
        <p className="text-[13px] text-[#58626C] capitalize">Select</p>

        <Select
          variant="bordered"
          size="small"
          placeholder="Select Month"
          aria-label="Select Month"
          classNames={{
            trigger: [
              "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
              "data-[hover=true]:border-[#DBDADE]",
              "data-[focus=true]:border-[#DBDADE]",
              "data-[open=true]:border-[#DBDADE]",
            ],
          }}
          onChange={(e) => {
            console.log(e.target.value);
            setMonth(e.target.value);
          }}
          items={months}
        >
          {(month) => <SelectItem key={month.value}>{month.label}</SelectItem>}
        </Select>
      </div>
      <div className="flex justify-center items-center gap-4">
        <button
          className="flex flex-col items-center justify-between border-[0.5px] border-[#EA5455] rounded-xl text-[#EA5455] px-[15px] py-[10px] gap-2"
          onClick={getExportSalesMonthData}
          disabled={loading}
        >
          {loading ? (
            <span>Loading...</span>
          ) : (
            <>
              <img src={pdf} alt="pdf" />
              <p className="text-[11px] font-bold leading-[13px] text-[#EA5455] capitalize">
                PDF
              </p>
            </>
          )}
        </button>
        <button className="flex flex-col items-center justify-between border-[0.5px] border-[#FFD700] rounded-xl text-[#FFD700] px-[15px] py-[10px] gap-2">
          <img src={print} alt="print" />
          <p className="text-[11px] font-bold leading-[13px] text-[#FFD700] capitalize">
            Print
          </p>
        </button>
      </div>
    </>
  );
};

export default MonthlyModalContent;

import React, { useState, useEffect } from "react";
import Input from "../ui/Input";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { addProduct } from "../../api/AppManagement";
import { getStores } from "../../api/AppManagement";
import { toast } from "../toast/Toast";
const AddProductModal = ({ closeModal, setNewProduct }) => {
  const [selectCategory, setSelectCategory] = useState("individual");
  const [productName, setProductName] = useState("");
  const [weight, setWeight] = useState("");
  const [count, setCount] = useState("");
  const [selectedCarat, setSelectedCarat] = useState("18");
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [price, setPrice] = useState("");
  const [makeUp, setMakeUp] = useState("");
  const [stores, setStores] = useState("");
  const [loadingStores, setLoadingStores] = useState(false);

  useEffect(() => {
    const getStoresData = async () => {
      setLoadingStores(true);
      const { data } = await getStores();
      setStores(data.data);
      setLoadingStores(false);
    };
    getStoresData();
  }, []);

  const addProductHandler = async () => {
    try {
      const { data } = await addProduct({
        vendor_id: selectedStore,
        title: productName,
        standard: selectedCarat,
        weight: weight,
        amount: count,
        make_up: makeUp,
        type: selectCategory,
        price: price,
        product_type: selectedProductType,
      });
      setNewProduct(data.data);
      closeModal();
    } catch (error) {
      toast(error);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Category
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Category"
          aria-label="Select Category"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          onChange={(e) => setSelectCategory(e.target.value)}
        >
          <SelectItem key="individual" value="individual">
            individual
          </SelectItem>
          <SelectItem key="stock" value="stock">
            stock
          </SelectItem>
        </Select>
      </div>
      <Input
        title="Product Name"
        type="text"
        name="product_name"
        placeholder="new product"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
      />
      <div className="flex gap-4">
        <Input
          title="Count"
          type="number"
          name="count"
          placeholder="1"
          value={count}
          onChange={(e) => setCount(e.target.value)}
        />
        <Input
          title="Weight"
          type="number"
          name="weight"
          placeholder="2.1"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
      </div>
      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Carat
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Carat"
          aria-label="Select Carat"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          onChange={(e) => setSelectedCarat(e.target.value)}
        >
          <SelectItem key={"18"} value={"18"}>
            18
          </SelectItem>
          <SelectItem key={"21"} value={"21"}>
            21
          </SelectItem>
          <SelectItem key={"24"} value={"24"}>
            24
          </SelectItem>
        </Select>
      </div>
      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Store
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Store"
          aria-label="Select Store"
          isLoading={loadingStores}
          classNames={{
            trigger: [
              "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
              "data-[hover=true]:border-[#DBDADE]",
              "data-[focus=true]:border-[#DBDADE]",
              "data-[open=true]:border-[#DBDADE]",
            ],
          }}
          onChange={(e) => {
            setSelectedStore(e.target.value);
          }}
          items={stores ? stores : []}
        >
          {(item) => (
            <SelectItem key={item.id} value={item.id}>
              {item.attributes.name}
            </SelectItem>
          )}
        </Select>
      </div>
      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Product Type
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Product Type"
          aria-label="Select Product Type"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          onChange={(e) => setSelectedProductType(e.target.value)}
        >
          <SelectItem key={"مستعمل"} value={"مستعمل"}>
            مستعمل
          </SelectItem>
          <SelectItem key={"جديد"} value={"جديد"}>
            جديد
          </SelectItem>
        </Select>
      </div>
      <div className="flex gap-4">
        <Input
          title="Price"
          type="number"
          name="price"
          placeholder="123"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <Input
          title="Make Up"
          type="number"
          name="make up"
          placeholder="21"
          value={makeUp}
          onChange={(e) => setMakeUp(e.target.value)}
        />
      </div>
      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={addProductHandler}
        >
          Add
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddProductModal;

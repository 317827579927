// import axios from "axios";
import { API } from "./api";

export const login = (email, password) => {
  // const formData = new FormData();
  // formData.append("email", email);
  // formData.append("password", password);
  // return API.post("auth/login", formData);
  // const BASE_URL = "https://dafter.softa-solutions.com/api/";

  const userData = {
    email: email,
    password: password,
  };

  return API.post("auth/login", userData);
};

export const getPayments = (page) => {
  return API.get("payments", { params: { page: page } });
};

export const getFilteredPayments = (filter, page) => {
  return API.get("payments/filter", {
    params: { page: page, status: filter.status },
  });
};

export const addPayment = (paymentName) => {
  const formData = new FormData();
  formData.append("payment_name", paymentName);
  return API.post("payments", formData);
};

export const updatePayment = (id, paymentName) => {
  return API.put(`payments/${id}`, { payment_name: paymentName });
};

export const deletePayment = (id) => {
  return API.delete(`payments/${id}`);
};

export const changeStatusPayment = (id, status) => {
  return API.put(
    `payments/change-status/${id}`,
    {},
    {
      params: { status: status },
    }
  );
};

export const getPackages = (page) => {
  return API.get("packages/all", { params: { page: page } });
};

export const getFilteredPackages = (filter, page) => {
  return API.get(`packages/all`, {
    params: { page: page, status: filter.status },
  });
};

export const getSinglePackage = (id) => {
  return API.get(`packages/${id}`);
};

export const searchPackage = (query) => {
  const formData = new FormData();
  formData.append("name", query);
  return API.post("packages/search", formData);
};

export const addPackage = (singlePackage) => {
  const formData = new FormData();
  formData.append("name", singlePackage.name);
  formData.append("num_user", singlePackage.num_user);
  formData.append("price", singlePackage.price);
  formData.append("description", singlePackage.description);
  singlePackage.page_ids.forEach((page, index) =>
    formData.append(`page_ids[${index}]`, parseInt(page))
  );

  return API.post("packages", formData);
};

export const deletePackage = (id) => {
  return API.delete(`packages/${id}`);
};

export const updatePackage = (id, singlePackage) => {
  const formData = new FormData();
  formData.append("name", singlePackage.name);
  formData.append("num_user", singlePackage.num_user);
  formData.append("price", singlePackage.price);
  formData.append("description", singlePackage.description);
  singlePackage.page_ids.forEach((page, index) =>
    formData.append(`page_ids[${index}]`, parseInt(page))
  );

  return API.post(`packages/${id}`, formData);
};

export const changeStatusPackage = (id, status) => {
  return API.put(
    `packages/change-status/${id}`,
    {},
    {
      params: { status: status },
    }
  );
};

export const getAppPages = () => {
  return API.get("pages");
};

export const getRoles = () => {
  return API.get("roles");
};

export const addRole = (role) => {
  const formData = new FormData();
  formData.append("name", role.name);
  if (role.permissions) {
    role.permissions.forEach((permission, i) => {
      formData.append(`permissions[${i}]`, permission);
    });
  } else {
    formData.append(`permissions`, "");
    console.log("no permissions");
  }
  return API.post("roles", formData);
};

export const deleteRole = (id) => {
  return API.delete(`roles/${id}`);
};

export const updateRole = (id, role) => {
  return API.post(`roles/${id}`, role);
};

export const searchRole = (query) => {
  const formData = new FormData();
  formData.append("name", query);

  return API.post("roles/search-role", formData);
};

export const getStaff = () => {
  return API.get("users/get-all");
};

export const deleteStaff = (id) => {
  return API.delete(`users/${id}`);
};

export const updateStaff = (id, staff) => {
  return API.put(`users/${id}`, staff);
};

export const addStaff = (staff) => {
  const formatDate = new FormData();
  formatDate.append("name", staff.name);
  formatDate.append("email", staff.email);
  formatDate.append("password", staff.password);
  formatDate.append("phone", staff.phone);
  formatDate.append("role", staff.role);
  formatDate.append("role_id", staff.role_id);
  formatDate.append("image", staff.image);
  return API.post("users", formatDate);
};

export const getSingleStaff = (id) => {
  return API.get(`users/${id}`);
};

export const changeStatusStaff = (id, status) => {
  return API.put(
    `users/change-status/${id}`,
    {},
    {
      params: { status: status },
    }
  );
};

export const searchStaff = (query) => {
  const formData = new FormData();
  formData.append("name", query);
  return API.post("users/search", formData);
};

export const getFilteredStaff = (filter) => {
  return API.get(`users/filter`, {
    params: {
      role: filter.role,
      status: filter.status,
    },
  });
};

export const getInvoice = () => {
  return API.get("invoices");
};

export const getSingleInvoice = (id) => {
  return API.get(`invoices/${id}`);
};

export const deleteInvoice = (id) => {
  return API.delete(`invoices/${id}`);
};

export const addInvoice = (invoice) => {
  return API.post("invoices", invoice);
};

export const updateInvoice = (id, invoice) => {
  return API.put(`invoices/${id}`, invoice);
};

export const searchInvoice = (query) => {
  const formData = new FormData();
  formData.append("name", query);
  return API.post("invoices/search", formData);
};

export const getFilteredInvoice = (filter) => {
  return API.get(`invoices/filter`, {
    params: {
      status: filter.status,
    },
  });
};

export const getExportSalesDate = (start_date, end_date) => {
  return API.get("sales/report-sale-by-date", {
    params: {
      start_date: start_date,
      end_date: end_date,
    },
  });
};

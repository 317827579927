import React from 'react'

const StatCard = ({ title, number, icon }) => {
    return (
        <div className='flex-1 rounded-md p-6 bg-white flex justify-between shadow-gray-card'>
            <div>
                <p className='text-[#88819D] text-xs leading-[22px] mb-1'>{title}</p>
                <div className='flex gap-[6px] items-center'>
                    <p className='text-[#4B465C] text-[22px] leading-[30px] font-semibold'>
                        {number}
                    </p>
                </div>
            </div>
            <img src={icon} alt='icon' />
        </div>
    )
}

export default StatCard
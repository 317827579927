import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
// import CustomPagination from "../ui/CustomPagination";
import { Button, Select, SelectItem } from "@nextui-org/react";
import PlusBtn from "../ui/icons/PlusBtn";
import PackageModal from "./PackageModal";
import PackagesSingleTable from "./PackagesSingleTable";
import { useSearchParams } from "react-router-dom";
import {
  getFilterdPackages,
  getFilteredPackages,
  getPackages,
  searchPackage,
} from "../../api/DashboardManagement";
import Loader from "../ui/Loader";

// const itemsPerPage = 10;

const PackagesTable = ({ newPackage, setNewPackage }) => {
  const [packages, setPackages] = useState();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  let page = parseInt(searchParams.get("page")) || 1;

  const [selectedStatus, setSelectedStatus] = useState("");

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    const getSearchReasonsData = async () => {
      const { data } = await searchPackage(searchTerm);
      console.log("search", data);
      setPackages(data);
      setLoading(false);
    };
    if (searchTerm.trim().length > 0) {
      getSearchReasonsData();
    }
  }, [searchTerm]);

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    const getPackagesData = async () => {
      setLoading(true);
      const { data } = await getPackages(page);
      setPackages(data);
      setLoading(false);
    };
    if (searchTerm.trim().length === 0 && selectedStatus.trim().length === 0) {
      getPackagesData();
    }
  }, [page, searchTerm, selectedStatus]);

  useEffect(() => {
    if (newPackage) {
      // if (packages.data.length < 10) {
      setPackages((prev) => {
        return {
          ...prev,
          data: [newPackage, ...prev.data],
        };
        // meta: { ...prev.meta, total: prev.meta.total + 1 },
      });
    }
    // }
  }, [newPackage]);

  const deletePackage = (id) => {
    setPackages((prev) => {
      const updatedPackages = packages.data.filter(
        (product) => product.id !== id
      );
      return { ...prev, data: updatedPackages };
    });
  };

  const updatePackage = (newData) => {
    const updatedPackageIndex = packages.data.findIndex(
      (product) => parseInt(product.id) === parseInt(newData.id)
    );
    const updatedPackages = packages.data;
    updatedPackages[updatedPackageIndex] = newData;
    setPackages((prev) => {
      return { ...prev, data: [...updatedPackages] };
    });
  };

  const filterPackages = async (name, value) => {
    if (value.trim().length > 0) {
      let filter = {};
      filter[name] = value;
      setLoading(true);
      const { data } = await getFilteredPackages(filter, page);
      console.log("filter", selectedStatus, data);
      setPackages(data);
      setLoading(false);
    }
  };
  return (
    <div className="w-full flex flex-col gap-4 py-6 rounded-md bg-white shadow-gray-card">
      <div className="w-full py-4 px-6 flex max-lg:flex-col max-lg:gap-4 justify-between items-center">
        <div className="max-lg:w-full flex max-lg:flex-col gap-[10px] items-center justify-center">
          <Button
            startContent={<PlusBtn />}
            className="max-lg:w-full bg-primary-500 text-white text-[13px] py-[6px] px-[14px] rounded-[4px] shadow-[0_2_4_0px]"
            onClick={openModal}
          >
            Create Package
          </Button>
        </div>
        <div className="max-lg:w-full flex max-lg:flex-col gap-4">
          <input
            placeholder="Search package"
            className="focus:outline-none text-[15px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Select
            variant="bordered"
            size="small"
            placeholder="Status"
            aria-label="Status"
            classNames={{
              trigger:
                "dropdown min-w-[160px] w-full data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
            }}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              filterPackages("status", e.target.value);
            }}
          >
            <SelectItem key="1" value="1">
              Active
            </SelectItem>
            <SelectItem key="0" value="0">
              Inactive
            </SelectItem>
          </Select>
        </div>
      </div>

      {!loading && packages && (
        <PackagesSingleTable
          packages={packages.data}
          onDeletePackage={deletePackage}
          onUpdatePackage={updatePackage}
        />
      )}
      {loading && <Loader />}

      {/* {!loading && packages?.meta !== undefined && (
        <div className="px-6 flex flex-col lg:flex-row gap-4 justify-between items-center">
          <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
            Showing {(page - 1) * itemsPerPage + 1} to{" "}
            {(page - 1) * itemsPerPage + packages.data.length} of{" "}
            {packages.meta.total} entries
          </p>
          <div className="w-full flex justify-center lg:justify-end">
            {packages && (
              <CustomPagination
                page={page}
                count={Math.ceil(packages.meta.total / itemsPerPage)}
              />
            )}
          </div>
        </div>
      )} */}
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Add New Package">
        <PackageModal closeModal={closeModal} setNewPackage={setNewPackage} />
      </Modal>
    </div>
  );
};

export default PackagesTable;

import React, { useState } from "react";
import { Button } from "@nextui-org/button";
import { Switch } from "@nextui-org/switch";
import Input from "../ui/Input";
import SelectComponent from "../ui/SelectComponent";
import dollar from "../../assets/icons/dollar.svg";
import countUsers from "../../assets/icons/countUsers.svg";
import percentage from "../../assets/icons/percentage.svg";

const PackageModel = ({ closeModal }) => {
  const [isAddUserSelected, setIsAddUserSelected] = useState(false);
  const [isPaidSelected, setIsPaidSelected] = useState(false);

  const handleSwitch = () => {
    setIsAddUserSelected(!isAddUserSelected);
  };

  const handleSwitchPaid = () => {
    setIsPaidSelected(!isPaidSelected);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-start gap-1 w-full ">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          SELECT PLAN
        </label>
        <SelectComponent options={[]} placeholder="BASIC" />
      </div>

      <div className="flex items-start gap-4 w-full ">
        <Switch
          size="sm"
          classNames={{
            base: "w-full",
            wrapper: "group-data-[selected=true]:bg-primary-500",
            label: "text-[12px] capitalize font-normal text-[#4B465C]",
          }}
          onChange={handleSwitch}
        >
          ADD USERS
        </Switch>
        {isAddUserSelected && (
          <Input
            title="COUNT"
            type="number"
            name="count"
            placeholder="0"
            icon={countUsers}
          />
        )}
      </div>

      {isAddUserSelected && (
        <Input
          title="USERS COST"
          type="number"
          name="price"
          placeholder="0"
          icon={dollar}
        />
      )}

      <Switch
        size="sm"
        classNames={{
          base: "w-full",
          wrapper: "group-data-[selected=true]:bg-primary-500",
          label: "text-[12px] capitalize font-normal text-[#4B465C]",
        }}
        onChange={handleSwitchPaid}
      >
        PAID
      </Switch>

      {isPaidSelected && (
        <>
          {" "}
          <div className="flex flex-col items-start gap-1 w-full ">
            <label className="text-[13px] capitalize font-normal text-[#4B465C]">
              SELECT PAYMENT METHOD
            </label>
            <SelectComponent
              options={[]}
              placeholder="CASH"
              className="w-full"
            />
          </div>
          <div className="flex items-start gap-4 w-full ">
            <Input
              title="DISCOUNT"
              type="number"
              name="discount"
              placeholder="0"
              icon={percentage}
            />
            <Input
              title="Discount reason"
              type="text"
              name="discountReason"
              placeholder=""
            />
          </div>
          <Input
            title="TOTAL INCOME"
            type="number"
            name="totalIncome"
            placeholder="0"
            icon={dollar}
          />
        </>
      )}

      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
        >
          CONFIRM
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default PackageModel;

import { Table, TableCell, TableColumn, TableRow } from "@nextui-org/table";
import { TableBody, TableHeader } from "@react-stately/table";
import React, { useMemo, useState } from "react";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import { Chip } from "@nextui-org/chip";
import { formatDate } from "../../helpers/format-date";
import { deleteStoreRole } from "../../api/AppManagement";
import Modal from "../ui/Modal";
import EditPermissionsModal from "./EditPermissionsModal";
import { toast } from "../toast/Toast";

const columnsHeaders = [
  { id: "name", name: "Name", sortable: true },
  { id: "assigned", name: "Assigned To", sortable: true },
  { id: "created_date", name: "Created Date", sortable: true },
  { id: "actions", name: "Actions", sortable: false },
];

const RoleSingleTable = ({ roles, onDeleteRole, onUpdateRole }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const openEditModal = (reason) => {
    setSelectedRole(reason);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedRole(null);
  };

  const [sortDescriptor, setSortDescriptor] = useState({
    column: "store",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...roles].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [roles, sortDescriptor.column, sortDescriptor.direction]);

  const deleteRoleHandler = async (id) => {
    try {
      await deleteStoreRole(id);
      onDeleteRole(id);
    } catch (error) {
      toast(error);
    }
  };

  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          wrapper: "rounded-[0] px-0 py-0",
          th: "uppercase bg-transparent px-6 py-4 border-b",
          td: "py-8 px-6",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn key={column.id} allowsSorting={column.sortable}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.store} className="border-b">
              <TableCell className="capitalize">
                {item.attributes.type}
              </TableCell>
              <TableCell className="flex gap-2 capitalize">
                {item.plans.map((role, index) => (
                  <Chip
                    key={index}
                    classNames={{
                      base: [
                        `${
                          index === 0 ? "bg-[#7367F029]" : "bg-[#FF9F4329]"
                        } rounded-md`,
                      ],
                      content: [
                        `${
                          index === 0 ? "text-primary-500" : "text-[#EFE523]"
                        }`,
                      ],
                    }}
                  >
                    {role}
                  </Chip>
                ))}
              </TableCell>
              <TableCell className="capitalize">
                {formatDate(item.attributes["created_at"])}
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2 w-fit">
                  <img
                    src={edit}
                    alt="edit"
                    className="cursor-pointer"
                    onClick={() => openEditModal(item)}
                  />
                  <img
                    src={trash}
                    alt="trash"
                    className="cursor-pointer"
                    onClick={() => deleteRoleHandler(item.id)}
                  />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title="Edit Role"
      >
        {selectedRole && (
          <EditPermissionsModal
            closeModal={closeEditModal}
            role={selectedRole}
            onUpdateRole={onUpdateRole}
          />
        )}
      </Modal>
    </>
  );
};

export default RoleSingleTable;

import React from 'react';

const StoreReportsIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <path
                d='M7.33366 4.58334H5.50033C4.4878 4.58334 3.66699 5.40415 3.66699 6.41668V17.4167C3.66699 18.4292 4.4878 19.25 5.50033 19.25H10.7226'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.33366 4.58334H5.50033C4.4878 4.58334 3.66699 5.40415 3.66699 6.41668V17.4167C3.66699 18.4292 4.4878 19.25 5.50033 19.25H10.7226'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16.5 12.8333V16.5H20.1667'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16.5 12.8333V16.5H20.1667'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16.4997 10.0833V6.41668C16.4997 5.40415 15.6789 4.58334 14.6663 4.58334H12.833'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16.4997 10.0833V6.41668C16.4997 5.40415 15.6789 4.58334 14.6663 4.58334H12.833'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='7.33301'
                y='2.75'
                width='5.5'
                height='3.66667'
                rx='1.83333'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='7.33301'
                y='2.75'
                width='5.5'
                height='3.66667'
                rx='1.83333'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <circle
                cx='16.4997'
                cy='16.5'
                r='3.66667'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <circle
                cx='16.4997'
                cy='16.5'
                r='3.66667'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.33301 10.0833H10.9997'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.33301 10.0833H10.9997'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.33301 13.75H10.083'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.33301 13.75H10.083'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default StoreReportsIcon;

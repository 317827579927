import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { addStoreRole } from "../../api/AppManagement";
import BorderedCheckbox from "../ui/BorderedCheckbox";
import Input from "../ui/Input";
import { toast } from "../toast/Toast";

const RoleModalContent = ({ closeModal, setNewRole }) => {
  const [type, setType] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);

  const allRoles = [
    { label: "Gold Storage", value: 1 },
    { label: "Silver Storage", value: 2 },
    { label: "Bronze Storage", value: 3 },
  ];

  const addRoleHandler = async () => {
    const roleData = {
      type: type,
      plan_ids: selectedRoles,
    };

    try {
      const { data } = await addStoreRole(roleData);
      setNewRole(data.data);
      closeModal();
      console.log(roleData);
    } catch (error) {
      toast(error);
    }
  };

  const toggleRoleSelection = (event) => {
    const { checked, value } = event.target;
    // setSelectedRoles((prevRoles) => {
    //   const isSelected = prevRoles.includes(value);
    //   if (isSelected) {
    //     return prevRoles.filter((role) => role !== value);
    //   } else {
    //     return [...prevRoles, value];
    //   }
    // });
    if (checked) {
      setSelectedRoles((prevState) => [...prevState, value]);
    } else {
      setSelectedRoles((prevState) =>
        prevState.filter((item) => item !== value)
      );
    }
  };

  return (
    <div className="flex flex-col gap-[26px]">
      <Input
        title="Role Name"
        type="text"
        name="role name"
        placeholder="Sales"
        value={type}
        onChange={(e) => setType(e.target.value)}
      />
      <div className="flex flex-col gap-3 px-[18px]">
        {allRoles.map((role, index) => (
          <BorderedCheckbox
            key={index}
            label={role.label}
            value={role.value}
            checked={selectedRoles.includes(role.value)}
            // onChange={() => toggleRoleSelection(role.value)}
            onChangeHandler={toggleRoleSelection}
          />
        ))}
      </div>
      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-[15px] font-medium tracking-[0.43px] rounded-md py-[10px] px-[20px] shadow-gray-sm capitalize"
          onClick={addRoleHandler}
        >
          Submit
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-[15px] font-medium tracking-[0.43px] rounded-md py-[10px] px-[20px] shadow-gray-sm capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default RoleModalContent;

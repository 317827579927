import React, { useState, useMemo } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  DropdownItem,
} from "@nextui-org/react";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import dots from "../../assets/icons/dots-vertical.svg";
import eye from "../../assets/icons/eye.svg";
import { deleteRole, updateRole } from "../../api/DashboardManagement";
import DropdownMenus from "../ui/DropdownMenus";
import ModalRole from "./ModalRole";
import EditModalRoleContent from "./EditModalRoleContent";
import { toast } from "../toast/Toast";

const columnsHeaders = [
  { id: "role", name: "Role", sortable: true },
  { id: "actions", name: "Actions", sortable: false },
];

const RoleSingleTable = ({
  data,
  onDeleteRole,
  onUpdateRole,
  name,
  setName,
  permissions,
  setPermissions,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const [sortDescriptor, setSortDescriptor] = useState({
    column: "store",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...data].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, data]);

  const deleteRoleHandler = async (id) => {
    try {
      await deleteRole(id);
      onDeleteRole(id);
    } catch (error) {
      toast(error);
    }
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const openEditModal = (role) => {
    setSelectedRole(role);
    setIsEditModalOpen(true);
    setIsEdit(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedRole(null);
  };

  const updateRoleHandler = async () => {
    try {
      await updateRole(data.id, {
        name,
        permissions,
      });
      onUpdateRole(data.data);
      closeEditModal();
    } catch (error) {
      toast(error);
    }
  };

  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          base: "overflow-x-auto",
          wrapper: "rounded-[0] px-0 py-0",
          th: "uppercase bg-transparent px-6 py-4 border-b",
          td: "py-8 px-6",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn key={column.id} allowsSorting={column.sortable}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.id} className="border-b">
              <TableCell className="lg:w-[70%]">
                {item?.attribute?.name}
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2 w-fit">
                  <img
                    src={edit}
                    alt="edit"
                    className="cursor-pointer"
                    onClick={() => openEditModal(item)}
                  />
                  <img
                    src={trash}
                    alt="trash"
                    className="cursor-pointer"
                    onClick={() => deleteRoleHandler(item.id)}
                  />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <ModalRole
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title={"Edit Role"}
        updateRoleHandler={updateRoleHandler}
        isEdit={isEdit}
      >
        {selectedRole && (
          <EditModalRoleContent
            closeModal={closeEditModal}
            roleData={selectedRole}
            name={name}
            setName={setName}
            permissions={permissions}
            setPermissions={setPermissions}
          />
        )}
      </ModalRole>
    </>
  );
};

export default RoleSingleTable;

import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import DashboardIcon from "./icons/DashboardIcon";
import { MANAGE_APP, MANAGE_DASHBOARD } from "../../util/menu-links";
import MenuItem from "./MenuItem";
import logo from "../../assets/icons/logo.svg";
import menu from "../../assets/icons/burger-menu.svg";
import LogoutIcon from "./icons/LogoutIcon";
import { useNavigate } from "react-router";

const MobileMenu = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
    if (!showMenu) {
      document.body.classList.add("opened-model");
    } else {
      document.body.classList.remove("opened-model");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <img
        src={menu}
        alt="menu"
        className="sm:hidden w-[26px] h-[26px] cursor-pointer"
        onClick={toggleMenu}
      />
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ x: -1000 }}
            animate={{ x: 0 }}
            exit={{ x: -1000 }}
            transition={{ duration: 0.3 }}
            className="sm:hidden w-full h-[calc(100vh-62px)] absolute top-[62px] left-0 z-50 overflow-auto scroll bg-white shadow-gray-sm px-[22px]"
          >
            <div className="flex py-[20px] justify-between">
              <div className="flex gap-[10px] items-center">
                <img src={logo} alt="logo" />
                <h1 className="font-bold text-md uppercase text-[#4B465C]">
                  Dafatar
                </h1>
              </div>
            </div>
            <MenuItem
              name="Dashboard"
              url="/"
              icon={<DashboardIcon color="#4B465C" />}
              activeIcon={<DashboardIcon color="#fff" />}
              onClickHandler={() => setShowMenu(false)}
            />
            <p className="mb-[6px] text-[11px] ms-[24px] text-[#4B465C] group-[.collapse-menu]:hidden">
              Manage Mobile App
            </p>
            {MANAGE_APP.map((item) => (
              <MenuItem
                key={item.name}
                name={item.name}
                url={item.url}
                icon={item.icon}
                activeIcon={item.activeIcon}
                onClickHandler={() => setShowMenu(false)}
              />
            ))}
            <p className="my-[6px] text-[11px] ms-[24px] text-[#4B465C] group-[.collapse-menu]:hidden">
              Manage Daftar Dhabi
            </p>
            {MANAGE_DASHBOARD.map((item) => (
              <MenuItem
                key={item.name}
                name={item.name}
                url={item.url}
                icon={item.icon}
                activeIcon={item.activeIcon}
                onClickHandler={() => setShowMenu(false)}
              />
            ))}
            <p className="my-[6px] text-[11px] ms-[24px] text-[#4B465C] group-[.collapse-menu]:hidden">
              Settings
            </p>
            <div
              className="flex gap-[10px] items-center px-[16px] py-[9px] cursor-pointer"
              onClick={handleLogout}
            >
              <LogoutIcon />
              <h1 className="font-semibold text-xs leading-[22px]">Logout</h1>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu;

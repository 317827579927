import React, { useState } from "react";
import Stats from "./Stats";
import StoresTable from "./StoresTable";
import Modal from "../ui/Modal";
import ModalContent from "../stores/ModalContent";
const Stores = () => {
  const [newStore, setNewStore] = useState();

  const [isModelOpen, setIsModelOpen] = useState(false);

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">Store</h3>
      <Stats />
      <StoresTable openModal={openModal} newStore={newStore} />
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Add Store">
        <ModalContent closeModal={closeModal} setNewStore={setNewStore} />
      </Modal>
    </section>
  );
};

export default Stores;

import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  DropdownItem,
} from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import edit from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import status from '../../assets/icons/toggle-status.svg'
import { formatDate } from "../../helpers/format-date";
import Modal from "../ui/Modal";
import EditReasonModal from "./EditReasonModal";
import { changeStatusReasons, deleteReason } from "../../api/AppManagement";
import DropdownMenus from "../ui/DropdownMenus";
const columnsHeaders = [
  { id: "reason_name", name: "Reason Name", sortable: true },
  { id: "reason_type", name: "Reason Type", sortable: true },
  { id: "store_name", name: "Store Name", sortable: true },
  { id: "date_added", name: "Date Added", sortable: true },
  { id: "status", name: "Status", sortable: true },
  { id: "actions", name: "Actions", sortable: false },
];

const ReasonsSingleTable = ({ data, onDeleteReason, onUpdateReason }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const openEditModal = (reason) => {
    setSelectedReason(reason);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedReason(null);
  };

  const deleteReasonHandler = async (id) => {
    await deleteReason(id);
    onDeleteReason(id);
  };

  const [sortDescriptor, setSortDescriptor] = useState({
    column: "reason_name",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...data].sort((a, b) => {
      let first;
      let second;
      let cmp;
      switch (sortDescriptor.column) {
        case "reason_name":
          first = a.attributes.name;
          second = b.attributes.name;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "reason_type":
          first = a.attributes.type;
          second = b.attributes.type;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "store_name":
          first = a.vendor.name;
          second = b.vendor.name;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "date_added":
          first = a.attributes["created_at"];
          second = b.attributes["created_at"];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "status":
          first = a.attributes.status;
          second = b.attributes.status;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;

        default:
          first = a[sortDescriptor.column];
          second = b[sortDescriptor.column];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
      }
    });
  }, [data, sortDescriptor]);

  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus == 1 ? 0 : 1;
    const { data } = await changeStatusReasons(id, newStatus);
    onUpdateReason(data.data);
  };

  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          wrapper: "rounded-[0] px-0 py-0",
          th: "uppercase bg-transparent px-6 py-4 border-b",
          td: "py-8 px-6",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn key={column.id} allowsSorting={column.sortable}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.id} className="border-b">
              <TableCell>{item.attributes.name}</TableCell>
              <TableCell>{item.attributes.name}</TableCell>
              <TableCell>
                <div className="flex items-center gap-[10px]">
                  <img
                    src={item.vendor.image}
                    alt="store_img"
                    className="w-[38px] h-[38px] rounded-full"
                  />
                  <div className="flex flex-col">
                    <p className="text-[#4B465C] font-semibold text-[15px] leading-[22px] capitalize">
                      {item.vendor.name}
                    </p>
                    <p className="text-[#4B465C] text-[13px] leading-5 capitalize text-ellipsis overflow-hidden w-[120px]">
                      {item.vendor.email}
                    </p>
                  </div>
                </div>
              </TableCell>
              <TableCell>{formatDate(item.attributes["created_at"])}</TableCell>
              <TableCell>
                <Chip
                  radius="sm"
                  className={`p-[5px 10px] text-sm font-semibold capitalize ${item.attributes.status == 1
                    ? "bg-success-200 text-success-500"
                    : "bg-gray-200 text-gray-500"
                    }`}
                >
                  {item.attributes.status == 1 ? "Active" : "Inactive"}
                </Chip>
              </TableCell>
              <TableCell>
                <div className="flex gap-2 items-center w-fit">
                  <div className="flex gap-2 items-center">
                    <img
                      src={edit}
                      alt="edit"
                      className="cursor-pointer"
                      onClick={() => openEditModal(item)}
                    />
                    <img
                      src={trash}
                      alt="trash"
                      className="cursor-pointer"
                      onClick={() => deleteReasonHandler(item.id)}
                    />
                  </div>
                  <DropdownMenus>
                    <DropdownItem
                      key="status"
                      startContent={
                        <img src={status} alt="toggle" />
                      }
                      onClick={() => {
                        toggleStatus(item.id, item.attributes.status);
                        console.log(item.id, item.attributes.status);
                      }}
                    >
                      Status
                    </DropdownItem>
                  </DropdownMenus>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title="Edit Reason"
      >
        {selectedReason && (
          <EditReasonModal
            closeModal={closeEditModal}
            reason={selectedReason}
            onUpdateReason={onUpdateReason}
          />
        )}
      </Modal>
    </>
  );
};

export default ReasonsSingleTable;

import {
  Chip,
  DropdownItem,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changeStatusStore, deleteStore } from "../../api/AppManagement";
import edit from "../../assets/icons/edit.svg";
import eye from "../../assets/icons/eye.svg";
import status from "../../assets/icons/toggle-status.svg";
import trash from "../../assets/icons/trash.svg";
import avatar from "../../assets/images/userImg.png";
import { formatDate } from "../../helpers/format-date";
import DropdownMenus from "../ui/DropdownMenus";
import Modal from "../ui/Modal";
import EditStoreModal from "./EditStoreModal";
import { toast } from "../toast/Toast";

const columnsHeaders = [
  { id: "store", name: "Store", sortable: true },
  { id: "plan", name: "Plan", sortable: true },
  { id: "paid_status", name: "Paid Status", sortable: true },
  { id: "users", name: "Users", sortable: true },
  { id: "date", name: "Date", sortable: true },
  { id: "billing", name: "Billing", sortable: true },
  { id: "status", name: "Status", sortable: true },
  { id: "actions", name: "Actions", sortable: false },
];

const StoresSingleTable = ({ stores, onDeleteStore, onUpdateStore }) => {
  const navigate = useNavigate();

  const [sortDescriptor, setSortDescriptor] = useState({
    column: "store",
    direction: "ascending",
  });

  const sortedItems = useMemo(() => {
    return [...stores].sort((a, b) => {
      let first;
      let second;
      let cmp;
      switch (sortDescriptor.column) {
        case "store":
          first = a.attributes.name;
          second = b.attributes.name;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "date":
          first = a.attributes["created_at"];
          second = b.attributes["created_at"];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "paid_status":
          first = a.attributes["paid_status"];
          second = b.attributes["paid_status"];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        case "status":
          first = a.attributes.status;
          second = b.attributes.status;
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
        default:
          first = a[sortDescriptor.column];
          second = b[sortDescriptor.column];
          cmp = first < second ? -1 : first > second ? 1 : 0;
          return sortDescriptor.direction === "descending" ? -cmp : cmp;
      }
    });
  }, [sortDescriptor, stores]);

  const deleteStoreHandler = async (id) => {
    try {
      await deleteStore(id);
      onDeleteStore(id);
    } catch (error) {
      toast(error);
    }
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const openEditModal = (store) => {
    setSelectedStore(store);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedStore(null);
  };

  const toggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus == 1 ? 0 : 1;
    const { data } = await changeStatusStore(id, newStatus);
    onUpdateStore(data.data);
  };

  return (
    <>
      <Table
        aria-label="stores_table"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          wrapper: "rounded-[0] px-0 py-0",
          th: "uppercase bg-transparent px-6 py-4 border-b",
          td: "py-8 px-6",
        }}
      >
        <TableHeader columns={columnsHeaders}>
          {(column) => (
            <TableColumn key={column.id} allowsSorting={column.sortable}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={sortedItems} emptyContent={"No stores found"}>
          {(item) => (
            <TableRow key={item.id} className="border-b">
              <TableCell>
                <div className="flex gap-[10px]">
                  <img
                    src={item.attributes.image ? item.attributes.image : avatar}
                    alt={item.attributes.name}
                    className="w-[38px] h-[38px] rounded-[50%]"
                  />
                  <div>
                    <p>{item.attributes.name}</p>
                    <p className="text-[#4b465c61] text-[13px] truncate w-[100px]">
                      {item.attributes.email}
                    </p>
                  </div>
                </div>
              </TableCell>
              <TableCell>{item.plan.title}</TableCell>
              <TableCell>{item.attributes["paid_status"]}</TableCell>
              <TableCell>{item.users.length} Users</TableCell>
              <TableCell>{formatDate(item.attributes["created_at"])}</TableCell>
              <TableCell>{"billing"}</TableCell>
              <TableCell>
                <Chip
                  radius="sm"
                  className={`p-[5px 10px] text-sm font-semibold capitalize ${
                    item.attributes.status === 1
                      ? "bg-success-200 text-success-500"
                      : "bg-secondary-200 text-secondary-500"
                  }`}
                >
                  {item.attributes.status === 1 ? "Active" : "Inactive"}
                </Chip>
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2 w-fit">
                  <img
                    src={edit}
                    alt="edit"
                    className="cursor-pointer"
                    onClick={() => openEditModal(item)}
                  />
                  <img
                    src={trash}
                    alt="trash"
                    className="cursor-pointer"
                    onClick={() => deleteStoreHandler(item.id)}
                  />
                  <DropdownMenus>
                    <DropdownItem
                      key="view"
                      startContent={
                        <img src={eye} alt="eye" className="cursor-pointer" />
                      }
                      onClick={() =>
                        navigate(`/stores/store/${item.id}/account`)
                      }
                    >
                      View
                    </DropdownItem>
                    <DropdownItem
                      key="status"
                      startContent={<img src={status} alt="toggle" />}
                      onClick={() => {
                        toggleStatus(item.id, item.attributes.status);
                        console.log(item.id, item.attributes.status);
                      }}
                    >
                      Status
                    </DropdownItem>
                  </DropdownMenus>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title="Edit Store"
      >
        {selectedStore && (
          <EditStoreModal
            closeModal={closeEditModal}
            storeData={selectedStore}
            onUpdateStore={onUpdateStore}
          />
        )}
      </Modal>
    </>
  );
};

export default StoresSingleTable;

import { Button, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { getStores, updateProduct } from "../../api/AppManagement";
import Input from "../ui/Input";

const EditProductModal = ({
  closeModal,
  productData,
  onUpdateProduct,
  toggleEditing,
  isEditing,
}) => {
  console.log(productData);

  const [selectCategory, setSelectCategory] = useState(
    productData.attributes.type
  );
  const [productName, setProductName] = useState(productData.attributes.title);
  const [weight, setWeight] = useState(productData.attributes.weight);
  const [count, setCount] = useState(productData.attributes.amount);
  const [selectedCarat, setSelectedCarat] = useState(
    `${productData.attributes.standard}`
  );
  const [selectedStore, setSelectedStore] = useState(
    productData.attributes.vendor_id
  );
  const [price, setPrice] = useState(productData.attributes.price);
  const [makeUp, setMakeUp] = useState(productData.attributes.make_up);
  const [selectedProductType, setSelectedProductType] = useState(
    productData.attributes.product_type
  );
  const [loadingStores, setLoadingStores] = useState(false);
  const [stores, setStores] = useState("");

  useEffect(() => {
    const getStoresData = async () => {
      setLoadingStores(true);
      const { data } = await getStores();
      console.log(data);
      setStores(data.data);
      setLoadingStores(false);
    };
    getStoresData();
  }, []);

  const updateProductHandler = async () => {
    const { data } = await updateProduct(productData.id, {
      vendor_id: selectedStore,
      title: productName,
      standard: selectedCarat,
      weight: weight,
      amount: count,
      make_up: makeUp,
      type: selectCategory,
      price: price,
      product_type: selectedProductType,
    });
    closeModal();
    console.log(data);
    onUpdateProduct(data.data);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-start gap-1 w-full">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Category
        </label>
        <Select
          onChange={(e) => setSelectCategory(e.target.value)}
          variant="bordered"
          size="small"
          placeholder="Select Category"
          aria-label="Select Category"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          selectedKeys={[selectCategory]}
          defaultSelectedKeys={[selectCategory]}
          isDisabled={!isEditing}
        >
          <SelectItem key="individual" value="individual">
            individual
          </SelectItem>
          <SelectItem key="stock" value="stock">
            stock
          </SelectItem>
        </Select>
      </div>
      <Input
        title="Product Name"
        type="text"
        name="product_name"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
        disabled={!isEditing}
      />
      <div className="flex gap-4">
        <Input
          title="Count"
          type="number"
          name="count"
          placeholder="1"
          value={count}
          onChange={(e) => setCount(e.target.value)}
          disabled={!isEditing}
        />
        <Input
          title="Weight"
          type="number"
          name="weight"
          placeholder="2.1"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          disabled={!isEditing}
        />
      </div>
      <div className="flex flex-col items-start gap-1 w-full">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Carat
        </label>
        <Select
          onChange={(e) => setSelectedCarat(e.target.value)}
          variant="bordered"
          size="small"
          placeholder="Select Carat"
          aria-label="Select Carat"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          selectedKeys={[selectedCarat]}
          defaultSelectedKeys={[selectedCarat]}
          isDisabled={!isEditing}
        >
          <SelectItem key={"18"} value={"18"}>
            18
          </SelectItem>
          <SelectItem key={"21"} value={"21"}>
            21
          </SelectItem>
          <SelectItem key={"24"} value={"24"}>
            24
          </SelectItem>
        </Select>
      </div>
      <div className="flex flex-col items-start gap-1 w-full">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Store
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Store"
          aria-label="Select Store"
          isLoading={loadingStores}
          classNames={{
            trigger: [
              "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
              "data-[hover=true]:border-[#DBDADE]",
              "data-[focus=true]:border-[#DBDADE]",
              "data-[open=true]:border-[#DBDADE]",
            ],
          }}
          // selectedKeys={[selectedStore]}
          defaultSelectedKeys={[selectedStore]}
          isDisabled={!isEditing}
          onChange={(e) => {
            console.log(e.target.value);
            setSelectedStore(e.target.value);
          }}
          items={stores ? stores : []}
        >
          {(item) => (
            <SelectItem key={item.id} value={item.id}>
              {item.attributes.name}
            </SelectItem>
          )}
        </Select>
      </div>
      <div className="flex flex-col items-start gap-1 w-full">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Product Type
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Product Type"
          aria-label="Select Product Type"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          selectedKeys={[selectedProductType]}
          defaultSelectedKeys={[selectedProductType]}
          isDisabled={!isEditing}
          onChange={(e) => setSelectedProductType(e.target.value)}
        >
          <SelectItem key={"مستعمل"} value={"مستعمل"}>
            مستعمل
          </SelectItem>
          <SelectItem key={"جديد"} value={"جديد"}>
            جديد
          </SelectItem>
        </Select>
      </div>
      <div className="flex gap-4">
        <Input
          title="Price"
          type="number"
          name="price"
          placeholder="123"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          disabled={!isEditing}
        />
        <Input
          title="Make Up"
          type="number"
          name="make up"
          placeholder="21"
          value={makeUp}
          onChange={(e) => setMakeUp(e.target.value)}
          disabled={!isEditing}
        />
      </div>
      <div className="flex items-start gap-4 mt-[10px]">
        {isEditing ? (
          <Button
            size="sm"
            className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
            onClick={updateProductHandler}
          >
            Save
          </Button>
        ) : (
          <Button
            size="sm"
            className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
            onClick={toggleEditing}
          >
            Edit
          </Button>
        )}

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditProductModal;

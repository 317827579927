import { Button, Checkbox, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { addStaff, getRoles } from "../../api/DashboardManagement";
import upload from "../../assets/icons/upload.svg";
import Input from "../ui/Input";
import { toast } from "../toast/Toast";

const StaffModal = ({ closeModal, setNewStaff }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState();
  const [roles, setRoles] = useState();
  const [image, setImage] = useState();
  const [previewImage, setPreviewImage] = useState();
  const fileTypes = ["JPG", "PNG", "GIF"];

  const imageChangeHandler = (file) => {
    setImage(file);
    setPreviewImage(URL.createObjectURL(file));
  };

  let uploaderContent = (
    <>
      <img
        src={upload}
        alt="upload"
        className="mb-3 bg-secondary-300 p-[10px] rounded-md"
      />
      <p className="text-[15px] font-semibold text-[#4B465C] capitalize leading-[21px] text-center">
        Drop profile photo here or click to upload
      </p>
    </>
  );
  if (image) {
    // console.log(image);
    uploaderContent = (
      <img src={previewImage} alt="" className="w-[5rem] h-[5rem] bg-cover" />
    );
  }

  const addStaffHandler = async () => {
    try {
      const { data } = await addStaff({
        name,
        email,
        password,
        phone: "0120927922", // require
        role,
        role_id: "1", // require
        image,
      });
      setNewStaff(data.data);
      closeModal();
    } catch (error) {
      toast(error);
    }
  };

  useEffect(() => {
    const getRolesData = async () => {
      const { data } = await getRoles();
      // console.log(data);
      setRoles(data.data);
    };
    getRolesData();
  }, []);

  return (
    <div className="flex flex-col gap-[26px]">
      <Input
        title="Full Name"
        type="text"
        name="full_name"
        placeholder="John Doe"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          User Role
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Store"
          aria-label="Select Store"
          classNames={{
            trigger: [
              "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
              "data-[hover=true]:border-[#DBDADE]",
              "data-[focus=true]:border-[#DBDADE]",
              "data-[open=true]:border-[#DBDADE]",
            ],
          }}
          onChange={(e) => {
            console.log(e.target);
            setRole(e.target.value);
          }}
          items={roles ? roles : []}
        >
          {(item) => (
            <SelectItem key={item.id} value={item.id}>
              {item.attribute.name}
            </SelectItem>
          )}
        </Select>
      </div>

      <Input
        title="Email"
        type="email"
        name="email"
        placeholder="John.Doe@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Input
        title="Password"
        type="password"
        name="password"
        placeholder="*********"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <Input
        title="Confirm Password"
        type="password"
        name="confirm_password"
        placeholder="*********"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <FileUploader
        handleChange={imageChangeHandler}
        name="file"
        types={fileTypes}
        classes="border border-dashed border-[#DBDADE] flex flex-col items-center justify-center rounded-xl w-full p-[160px] py-[29px] px-[51px] cursor-pointer hover:border-secondary-700 hover:border-solid"
      >
        {uploaderContent}
      </FileUploader>
      <Checkbox
        color="default"
        classNames={{
          wrapper: [
            "group-data-[selected=true]:after:bg-primary-500",
            "group-data-[disabled=true]:bg-[#4B465C] group-data-[disabled=true]:before:border-[#4B465C]",
          ],
          icon: ["group-data-[selected=true]:text-white"],
          label: ["text-[#4B465C] text-[12px]"],
        }}
      >
        Send Email
      </Checkbox>

      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={addStaffHandler}
        >
          Add
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default StaffModal;

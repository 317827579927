import { Pagination, PaginationItemType } from "@nextui-org/react";
import React from "react";
import { useNavigate } from "react-router";

const renderItem = ({
  ref,
  key,
  value,
  isActive,
  onNext,
  onPrevious,
  setPage,
  className,
}) => {
  if (value === PaginationItemType.NEXT) {
    return (
      <button
        key={key}
        className="text-[#4B465C] bg-[#4B465C14] h-[34px] px-[10px] py-[4px] rounded-md"
        onClick={onNext}
      >
        Next
      </button>
    );
  }

  if (value === PaginationItemType.PREV) {
    return (
      <button
        key={key}
        className="text-[#4B465C] bg-[#4B465C14] h-[34px] px-[10px] py-[4px] rounded-md"
        onClick={onPrevious}
      >
        Previous
      </button>
    );
  }

  if (value === PaginationItemType.DOTS) {
    return (
      <button key={key} className={`${className} rounded-md h-[34px]`}>
        ...
      </button>
    );
  }

  // cursor is the default item
  return (
    <button
      key={key}
      ref={ref}
      className={` px-[10px] py-[4px] rounded-md w-[34px] h-[34px] ${
        isActive
          ? "text-white bg-primary-500 shadow-gray-sm"
          : "text-[#4B465C] bg-[#4B465C14] hover:bg-[#4B465C29]"
      }`}
      onClick={() => setPage(value)}
    >
      {value}
    </button>
  );
};

const CustomPagination = ({ page = 1, count }) => {
  const navigate = useNavigate();

  return (
    <Pagination
      renderItem={renderItem}
      disableCursorAnimation
      showControls
      total={count}
      initialPage={page}
      className="gap-2"
      radius="full"
      variant="light"
      onChange={(num) => navigate(`${num < 2 ? "" : `?page=${num}`}`)}
    />
  );
};

export default CustomPagination;

import React, { useEffect, useState } from "react";
import CustomPagination from "../ui/CustomPagination";
import RoleSingleTable from "./RoleSingleTable";
import { getStoreRole } from "../../api/AppManagement";
import { searchRole } from "../../api/DashboardManagement";
import { toast } from "../toast/Toast";

const RoleTable = ({ openModal, newRole }) => {
  const [roles, setRoles] = useState();
  const [loading, setLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    const getSearchReasonsData = async () => {
      const { data } = await searchRole(searchTerm);
      setRoles(data);
      setLoading(false);
    };
    if (searchTerm.trim().length > 0) {
      getSearchReasonsData();
    }
  }, [searchTerm]);

  useEffect(() => {
    const getRolesData = async () => {
      const { data } = await getStoreRole();
      setRoles(data);
      setLoading(false);
    };
    if (searchTerm.trim().length === 0) {
      getRolesData();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (newRole) {
      if (roles.data.length < 10) {
        setRoles((prev) => {
          return {
            ...prev,
            data: [newRole, ...prev.data],
            meta: { ...prev.meta, total: prev.meta.total + 1 },
          };
        });
      }
    }
  }, [newRole]);

  const deleteRole = (id) => {
    try {
      setRoles((prev) => {
        const updatedRole = roles.data.filter((role) => role.id !== id);
        return { ...prev, data: updatedRole };
      });
    } catch (error) {
      toast(error);
    }
  };

  const updateRole = (newData) => {
    const updatedRoleIndex = roles.data.findIndex(
      (role) => parseInt(role.id) === parseInt(newData.id)
    );
    const updatedRoles = roles.data;
    updatedRoles[updatedRoleIndex] = newData;
    setRoles((prev) => {
      return { ...prev, data: [...updatedRoles] };
    });
  };

  return (
    <div className="w-full flex flex-col py-6 gap-4 rounded-md bg-white shadow-[0px_4px_8px_0px_rgba(75, 70, 92, 0.10)]">
      <div className="flex flex-col items-center justify-between w-full gap-2 px-6 sm:flex-row">
        <div className="w-[200px] max-sm:w-full flex items-center border border-[#DBDADE] py-[7px] px-[14px] rounded-md">
          <input
            placeholder="Search"
            className="focus:outline-none flex-1 text-[15px]"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>

      {!loading && roles && (
        <RoleSingleTable
          roles={roles.data}
          onDeleteRole={deleteRole}
          onUpdateRole={updateRole}
        />
      )}

      <div className="flex flex-col items-center justify-between gap-4 px-6 lg:flex-row">
        <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
          Showing 1 to 10 of 100 entries
        </p>
        <div className="flex justify-center w-full lg:justify-end">
          <CustomPagination />
        </div>
      </div>
    </div>
  );
};

export default RoleTable;

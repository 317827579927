import React from 'react'
import AddInvoice from '../components/invoice/AddInvoice'

const AddInvoicePage = () => {
  return (
    <AddInvoice />
  )
}

export default AddInvoicePage

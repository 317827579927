import React, { useState } from "react";
import PackagesTable from "./PackagesTable";

const Packages = () => {
  const [newPackage, setNewPackage] = useState();

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">Packages</h3>
      <PackagesTable newPackage={newPackage} setNewPackage={setNewPackage} />
    </section>
  );
};

export default Packages;

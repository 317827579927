import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React from "react";

const columnsHeaders = [
  { id: "item", name: "Item" },
  { id: "description", name: "Description" },
  { id: "cost", name: "Cost" },
  { id: "qty", name: "Qty" },
  { id: "price", name: "Price" },
];

const PreviewTable = ({ items }) => {
  return (
    <Table
      aria-label="stores_table"
      classNames={{
        wrapper: "rounded-[0] px-0 py-0 bg-transparent",
        th: "uppercase bg-transparent px-6 py-4 border-b",
        td: "py-8 px-6",
      }}
    >
      <TableHeader columns={columnsHeaders}>
        {(column) => (
          <TableColumn key={column.id} allowsSorting={column.sortable}>
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody items={items} emptyContent={"No stores found"}>
        {(item) => (
          <TableRow key={item.id} className="border-b">
            <TableCell>{item.itemName}</TableCell>
            <TableCell className="capitalize">{item.description}</TableCell>
            <TableCell>EGP {item.cost}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>EGP {item.price}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default PreviewTable;

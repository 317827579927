import React, { useState, useEffect } from "react";
import MyTabBar from "./MyTabBar";
import SubscriptionCard from "./SubscriptionCard";
import UserCard from "./UserCard";
import { getSingleStore } from "../../api/AppManagement";
import { useParams } from "react-router";
const SingleStore = ({ children }) => {
  const params = useParams();
  const [store, setStore] = useState();
  useEffect(() => {
    const getStoreData = async () => {
      const { data } = await getSingleStore(params.id);
      setStore(data.data);
    };
    getStoreData();
  }, [params.id]);

  return (
    <div className="w-full flex flex-col gap-[26px]">
      <div className="mt-[26px]">
        <h3 className="text-secondary-400 text-md capitalize font-semibold text-[22px] leading-[30px]">
          Store/ <span className="text-[#4B465C]">Account</span>
        </h3>
      </div>

      <div className="w-full flex items-start gap-[26px] max-lg:flex-col">
        <div className="flex flex-col gap-[26px] w-full lg:w-[360px]">
          {store && <UserCard store={store} />}
        </div>

        <div className=" flex flex-col gap-[26px] w-full">
          <MyTabBar />

          {children}
        </div>
      </div>
    </div>
  );
};

export default SingleStore;

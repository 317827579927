import React, { useState } from "react";
import loginPic from "../assets/images/login.png";
import emailIcon from "../assets/icons/email.svg";
import closeEye from "../assets/icons/close_eye.svg";
import openEye from "../assets/icons/open_eye.svg";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "@nextui-org/react";
import { login } from "../api/DashboardManagement";
import axios from "axios";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const storageData = localStorage.getItem("userData");
  const user = storageData ? JSON.parse(storageData) : [];

  // const loginHandler = async () => {
  //   try {
  //     const { data } = await login(email, password);

  //     if (data.authentication) {
  //       const { token } = data.authentication;

  //       localStorage.setItem("token", JSON.stringify(token));
  //       localStorage.setItem("remember", rememberMe);
  //       localStorage.setItem("userData", JSON.stringify(data));



  //       navigate("/");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     console.log("Worked For Testing");
  //   }
  // };

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  };

  const handleLogin = async () => {
    try {
      const BASE_URL = "https://dafter.softa-solutions.com/api/";

      const userData = {
        email: email,
        password: password,
      };

      // axios.post(BASE_URL + "auth/login", userData);
      const { data } = await axios.post(BASE_URL + "auth/login", userData);
      const token = data.authentication.token;

      localStorage.setItem("token", token);

      localStorage.setItem("userData", JSON.stringify(userData));

      navigate("/");
    } catch (err) {
      alert("Error" + err);
    }
  };

  return (
    <div className="flex gap-4 h-screen w-screen">
      <div className="flex-1 w-full h-full px-4 flex justify-center items-center">
        <form
          className="sm:w-[549px]"
          onSubmit={(e) => {
            e.preventDefault();
            // loginHandler();
            handleLogin();
          }}
        >
          <h2 className="text-[#130707] text-[36px] leading-[44px] text-left mb-1">
            Welcome Back
          </h2>
          <p className="text-[#8692D0] mb-6">Hop back in!</p>
          <div className="py-[10px] px-[12px] border border-[#8692D0] rounded-[4px] flex justify-between mb-4">
            <input
              type="email"
              placeholder="example@test.com"
              className="flex-1 focus:outline-none"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validate();
              }}
            />
            <img src={emailIcon} alt="email" className="w-6 h-6" />
          </div>
          {!isValidEmail && (
            <p className="text-red-500 text-sm mb-2 -mt-1">
              Please enter a valid email
            </p>
          )}
          <div className="py-[10px] px-[12px] border border-[#8692D0] rounded-[4px] flex justify-between mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="password"
              className="flex-1 focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPassword ? (
              <img
                src={openEye}
                alt="hide password"
                className="cursor-pointer w-6 h-6"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <img
                src={closeEye}
                alt="show password"
                className="cursor-pointer w-6 h-6"
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
          <div className="flex justify-between mb-6">
            <Checkbox
              color="default"
              classNames={{
                wrapper: [
                  "group-data-[selected=true]:after:bg-primary-500",
                  "group-data-[disabled=true]:bg-[#4B465C] group-data-[disabled=true]:before:border-[#4B465C]",
                ],
                icon: ["group-data-[selected=true]:text-white"],
              }}
              onValueChange={(checked) => setRememberMe(checked)}
            >
              Remember me
            </Checkbox>
            <Link to="forgot-password" className="text-[#130707] text-[12px]">
              Forgot Password?
            </Link>
          </div>
          <button
            type="submit"
            disabled={!isValidEmail || password === ""}
            className="w-full bg-primary-500 text-white rounded-lg py-[14px] px-6 disabled:bg-gray-500"
          >
            Login
          </button>
        </form>
      </div>
      <div className="flex-1 max-lg:hidden">
        <img src={loginPic} alt="" className="w-full h-full" />
      </div>
    </div>
  );
};

export default LoginPage;

import React, { useEffect, useState } from "react";
import { Button, Checkbox } from "@nextui-org/react";
import Modal from "../ui/Modal";
import PackageModel from "./PackageModel";
import { getPlan } from "../../api/AppManagement";
import Loader from "../ui/Loader";

const Plan = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);

  const [plan, setPlan] = useState();
  const [loading, setLoading] = useState(true);

  const [selectedPages, setSelectedPages] = useState([]);

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    const getPlanData = async () => {
      const { data } = await getPlan();
      setLoading(false);
      setPlan(data.data);
      console.log(data);

      const defaultSelectedPages = data.data[0].pages.map(
        (page) => page.attribute.title
      );
      setSelectedPages(defaultSelectedPages);
    };
    getPlanData();
  }, []);

  const handleCheckboxChange = (pageTitle) => {
    setSelectedPages((prevSelectedPages) => {
      if (prevSelectedPages.includes(pageTitle)) {
        return prevSelectedPages.filter((page) => page !== pageTitle);
      } else {
        return Array.from(new Set([...prevSelectedPages, pageTitle]));
      }
    });
  };

  return (
    <div className="w-full flex flex-col gap-[26px]">
      <div className="flex flex-col p-6 gap-10 bg-white rounded-md shadow-[0px_4px_8px_0px_rgba(75, 70, 92, 0.10)]">
        <div className="flex flex-col items-start gap-[6px]">
          <h3 className="text-[#4B465C] text-lg capitalize font-semibold">
            Plans
          </h3>
          <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
            preview sections in mobile view
          </p>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="flex items-start justify-center gap-6 px-5 max-lg:flex-col">
            <div className="w-1/2 flex flex-col items-start justify-center gap-6">
              <p className="text-[#4B465C] text-[15px] leading-[21px] font-semibold capitalize">
                Plan: {plan[0].attributes.title}
              </p>
              <div className="w-full flex flex-col gap-[6px] items-start">
                <p className="text-[#4B465C] text-xs leading-[22px]">
                  13 users max limit
                </p>
                {selectedPages.length > 0 ? (
                  <p className="text-[#4B465C] text-xs leading-[22px]">
                    {selectedPages.map((page) => page).join(", ")}
                  </p>
                ) : (
                  <p className="text-[#4B465C] text-xs leading-[22px]">
                    There isn't page in this plan please add some
                  </p>
                )}
                <div className="w-full flex justify-between items-center">
                  <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                    subscribe cost:
                  </p>
                  <p className="text-[#4B465C] text-[15px] leading-[22px] font-semibold">
                    $ {plan[0].attributes.price}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-1/2 flex flex-col items-start justify-center gap-3">
              <p className="text-[#4B465C] text-[15px] leading-[21px] font-semibold capitalize">
                Plan: add
              </p>
              <div className="w-full flex gap-3 items-start">
                <div className="w-full flex flex-col gap-[6px] items-start">
                  <p className="text-[#4B465C] text-xs leading-[22px]">
                    2 users max limit
                  </p>
                  <div className="w-full flex justify-between items-center">
                    <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                      subscribe cost:
                    </p>
                    <p className="text-[#4B465C] text-[15px] leading-[22px] font-semibold">
                      $ 5
                    </p>
                  </div>
                </div>

                <div className="w-full flex flex-col gap-[6px] items-start">
                  <p className="text-[#4B465C] text-xs leading-[22px]">
                    2 users max limit
                  </p>
                  <div className="w-full flex justify-between items-center">
                    <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
                      subscribe cost:
                    </p>
                    <p className="text-[#4B465C] text-[15px] leading-[22px] font-semibold">
                      $ 5
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[9px] px-[19px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={openModal}
        >
          add or change store plans
        </Button>
      </div>

      <div>
        <div className="flex flex-col p-6 gap-10 bg-white rounded-md shadow-[0px_4px_8px_0px_rgba(75, 70, 92, 0.10)]">
          <div className="flex flex-col items-start gap-[6px]">
            <h3 className="text-[#4B465C] text-lg capitalize font-semibold">
              App pages
            </h3>
            <p className="text-[#4B465C] text-xs leading-[22px] capitalize">
              preview sections in mobile view
            </p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="w-full flex items-start p-5 bg-[#4B465C05] max-lg:flex-col">
              {plan[0].pages.map((page) => (
                <Checkbox
                  key={page.id}
                  value={page.attribute.title}
                  className="w-full py-[16.5px] px-[20px] capitalize font-semibold"
                  color="default"
                  classNames={{
                    wrapper: [
                      `group-data-[selected=true]:after:bg-primary-500`,
                    ],
                    icon: [`group-data-[selected=true]:text-white`],
                  }}
                  checked={selectedPages.includes(page.attribute.title)}
                  defaultSelected={selectedPages.includes(page.attribute.title)}
                  onChange={() => handleCheckboxChange(page.attribute.title)}
                >
                  {page.attribute.title}
                </Checkbox>
              ))}
            </div>
          )}

          <div className="flex items-start gap-4">
            <Button
              size="sm"
              className="w-max bg-primary-500 text-white text-sm rounded-md py-[9px] px-[19px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
            >
              Save Changes
            </Button>

            <Button
              size="sm"
              className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[9px] px-[19px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModelOpen}
        onClose={closeModal}
        title="Add or Change Package"
      >
        <PackageModel closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default Plan;

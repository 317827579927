import React from "react";
import { Button } from "@nextui-org/button";
import Input from "../ui/Input";

const EditNotificationsModal = ({ closeModal }) => {
  return (
    <div className="flex flex-col gap-4">
      <Input title="TITLE" type="text" name="title" placeholder="Title" />
      <Input
        title="DESCRIPTION"
        type="desc"
        name="description"
        placeholder="Description"
        className="h-[117px]"
      />

      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
        >
          Save
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditNotificationsModal;

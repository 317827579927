import React from 'react'
import AdminRole from '../components/admin role/AdminRole'

const AdminRolePage = () => {
  return (
    <AdminRole />
  )
}

export default AdminRolePage

import React from "react";
import InvoiceTable from "./InvoiceTable";

const Invoice = () => {
  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="page-heading">Invoice</h3>
      <InvoiceTable />
    </section>
  );
};

export default Invoice;

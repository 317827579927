import React from 'react';

const InvoicesIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <path
                d='M12.833 2.75V6.41667C12.833 6.92293 13.2434 7.33333 13.7497 7.33333H17.4163'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.833 2.75V6.41667C12.833 6.92293 13.2434 7.33333 13.7497 7.33333H17.4163'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.583 19.25H6.41634C5.40382 19.25 4.58301 18.4292 4.58301 17.4167V4.58333C4.58301 3.57081 5.40382 2.75 6.41634 2.75H12.833L17.4163 7.33333V17.4167C17.4163 18.4292 16.5955 19.25 15.583 19.25Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.583 19.25H6.41634C5.40382 19.25 4.58301 18.4292 4.58301 17.4167V4.58333C4.58301 3.57081 5.40382 2.75 6.41634 2.75H12.833L17.4163 7.33333V17.4167C17.4163 18.4292 16.5955 19.25 15.583 19.25Z'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.25 6.41668H9.16667'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.25 6.41668H9.16667'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.25 11.9167H13.75'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.25 11.9167H13.75'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.917 15.5833H13.7503'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.917 15.5833H13.7503'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default InvoicesIcon;

import React from 'react'
import Invoice from '../components/invoice/Invoice'

const InvoicePage = () => {
  return (
    <Invoice />
  )
}

export default InvoicePage

import React, { useEffect, useState } from "react";
import upload from "../../assets/icons/upload.svg";
import { getPlans, updateStore } from "../../api/AppManagement";
import Input from "../ui/Input";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { FileUploader } from "react-drag-drop-files";
import { egypt_cities } from "../../util/cities";
import { toast } from "../toast/Toast";

const EditStoreModal = ({ closeModal, storeData, onUpdateStore }) => {
  const [image, setImage] = useState(storeData.attributes.image);
  const [uploadedImage, setUploadedImage] = useState();
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [storeName, setStoreName] = useState(storeData.attributes.name);
  const [email, setEmail] = useState(storeData.attributes.email);
  const [phone, setPhone] = useState(storeData.attributes.contact);
  const [address, setAddress] = useState(storeData.attributes.address);
  const [city, setCity] = useState(storeData.attributes.city);
  const [plans, setPlans] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(
    storeData.attributes.plan_id
  );
  const [selectedStatus, setSelectedStatus] = useState(
    storeData.attributes["paid_status"]
  );
  useEffect(() => {
    const getPlansData = async () => {
      setLoading(true);
      const { data } = await getPlans();
      setPlans(data.data);
      setLoading(false);
    };
    getPlansData();
  }, []);
  const imageChangeHandler = (file) => {
    setUploadedImage(file);
    setImage(URL.createObjectURL(file));
  };

  let uploaderContent = (
    <>
      <img
        src={upload}
        alt="upload"
        className="mb-3 bg-secondary-300 p-[10px] rounded-md"
      />
      <p className="text-[15px] font-semibold text-[#4B465C] capitalize leading-[21px] text-center">
        Drop profile photo here or click to upload
      </p>
    </>
  );
  if (image) {
    console.log(image);
    uploaderContent = (
      <img
        src={uploadedImage ? URL.createObjectURL(uploadedImage) : image}
        alt=""
        className="w-[5rem] h-[5rem] bg-cover"
      />
    );
  }

  const updateStoreHandler = async () => {
    try {
      console.log(storeData);
      const { data } = await updateStore(storeData.id, {
        name: storeName,
        email: email,
        contact: parseInt(phone),
        address: address,
        city: city,
        plan_id: selectedPlan,
        paid_status: selectedStatus,
        image: uploadedImage,
      });
      console.log(data);
      // onUpdateStore(data.data);
      window.location.reload();
      closeModal();
    } catch (error) {
      toast(error);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Input
        title="Store Name"
        type="text"
        name="name"
        placeholder="John Doe"
        value={storeName}
        onChange={(e) => setStoreName(e.target.value)}
      />

      <Input
        title="Email"
        type="email"
        name="email"
        placeholder="John.Doe@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Input
        title="Contact"
        type="tel"
        name="phone"
        placeholder="873 857 8392"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />

      <Input
        title="Address"
        type="text"
        name="address"
        placeholder="PIXINVENT"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />

      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          City
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Alexandria"
          aria-label="Alexandria"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          selectedKeys={[city]}
          onChange={(e) => setCity(e.target.value)}
          items={egypt_cities}
        >
          {(item) => (
            <SelectItem key={item.name} value={item.name}>
              {item.name}
            </SelectItem>
          )}
        </Select>
      </div>

      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Select Plan
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="Select Plan"
          aria-label="Select Plan"
          isLoading={loading}
          classNames={{
            trigger: [
              "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
              "data-[hover=true]:border-[#DBDADE]",
              "data-[focus=true]:border-[#DBDADE]",
              "data-[open=true]:border-[#DBDADE]",
            ],
          }}
          defaultSelectedKeys={[selectedPlan]}
          onChange={(e) => {
            console.log(e.target.value);
            setSelectedPlan(e.target.value);
          }}
          items={plans ? plans : []}
        >
          {(item) => (
            <SelectItem key={item.id} value={item.id}>
              {item.attributes.title}
            </SelectItem>
          )}
        </Select>
      </div>

      <div className="flex flex-col items-start w-full gap-1">
        <label className="text-[13px] capitalize font-normal text-[#4B465C]">
          Paid Status
        </label>
        <Select
          variant="bordered"
          size="small"
          placeholder="unpaid"
          aria-label="unpaid"
          classNames={{
            trigger:
              "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
          }}
          selectedKeys={[selectedStatus]}
          defaultSelectedKeys={[selectedStatus]}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <SelectItem key="unpaid" value="unpaid">
            unpaid
          </SelectItem>
          <SelectItem key="paid" value="paid">
            paid
          </SelectItem>
        </Select>
      </div>

      <FileUploader
        handleChange={imageChangeHandler}
        name="file"
        types={fileTypes}
        classes="border border-dashed border-[#DBDADE] flex flex-col items-center justify-center rounded-xl w-full p-[160px] py-[29px] px-[51px] cursor-pointer hover:border-secondary-700 hover:border-solid"
      >
        {uploaderContent}
      </FileUploader>

      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={updateStoreHandler}
        >
          Edit
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditStoreModal;

import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import CustomPagination from "../ui/CustomPagination";
import SelectComponent from "../ui/SelectComponent";
import PlusBtn from "../ui/icons/PlusBtn";
import RoleSingleTable from "./RoleSingleTable";
import { updateRole } from "../../api/DashboardManagement";

const itemsPerPage = 10;

const AdminRolesTable = ({ openModal, roles, page, setRoles, name, setName, permissions, setPermissions }) => {
  const [selectedNum, setSelectedNum] = useState(10);

  const deleteRole = (id) => {
    const updatedRole = roles.data.filter((product) => product.id !== id);
    setRoles((prev) => {
      return { ...prev, data: updatedRole };
    });
  };

  const updateRole = (newData) => {
    const updatedRoleIndex = roles.data.findIndex(
      (role) => parseInt(role.id) === parseInt(newData.id)
    );
    const updatedRoles = roles.data;
    updatedRoles[updatedRoleIndex] = newData;
    setRoles((prev) => {
      return { ...prev, data: [...updatedRoles] };
    });
  };


  return (
    <div className="flex flex-col gap-4 py-6 rounded-md bg-white shadow-gray-card">
      <div className="w-full py-4 px-6 flex max-lg:flex-col max-lg:gap-4 justify-between items-center">
        <div className="max-lg:w-full flex max-lg:flex-col gap-[10px] items-center justify-center">
          <Button
            startContent={<PlusBtn />}
            className="max-lg:w-full bg-primary-500 text-white text-[13px] py-[6px] px-[14px] rounded-[4px] shadow-[0_2_4_0px]"
            onClick={openModal}
          >
            Add New Role
          </Button>
        </div>
        <div className="max-lg:w-full flex max-lg:flex-col gap-4">
          <input
            placeholder="Search"
            className="focus:outline-none flex-1 text-[15px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md"
          />
        </div>
      </div>
      <RoleSingleTable
        data={roles.data}
        onDeleteRole={deleteRole}
        onUpdateRole={updateRole}
        name={name}
        setName={setName}
        permissions={permissions}
        setPermissions={setPermissions} />
      <div className="px-6 flex flex-col lg:flex-row gap-4 justify-between items-center">
        <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
          Showing {(page - 1) * itemsPerPage + 1} to{" "}
          {(page - 1) * itemsPerPage + roles.data.length} of {roles.meta.total}{" "}
          entries
        </p>
        <div className="w-full flex justify-center lg:justify-end">
          <CustomPagination
            page={page}
            count={Math.ceil(roles.meta.total / itemsPerPage)}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminRolesTable;

import { Button, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  changeStatusStaff,
  getRoles,
  getSingleStaff,
  updateStaff,
} from "../../api/DashboardManagement";
import Input from "../ui/Input";
import Loader from "../ui/Loader";
const StaffMember = ({ id }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let editing = searchParams.get("edit") === "true";

  const [staff, setStaff] = useState({});
  const [loading, setLoading] = useState(true);

  const [loadingRoles, setLoadingRoles] = useState(false);
  const [roles, setRoles] = useState();
  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    const staffData = async () => {
      const { data } = await getSingleStaff(id);
      console.log(data);
      setStaff(data);
      setLoading(false);
    };

    staffData();
  }, [id]);

  const updateStaffHandler = async () => {
    const { data } = await updateStaff(staff.data.id, {
      name: staff.data.attributes.name,
      email: staff.data.attributes.email,
      role: staff.data.attributes.role,
      // status: staff.data.status,
      // image: staff.data.image,
      phone: "02222",
      role_id: 1,
    });
    console.log(data);
    navigate(`/staff/${data.data.id}`);
  };

  useEffect(() => {
    const getRolesData = async () => {
      const { data } = await getRoles();
      console.log(data);
      setRoles(data.data);
      setLoadingRoles(false);
    };
    getRolesData();
  }, []);

  const selectStatus = async (id, currentStatus) => {
    const { data } = await changeStatusStaff(id, currentStatus);
    console.log(data);
  };

  return (
    <section className="flex flex-col gap-[26px] pt-[26px]">
      <h3 className="text-secondary-400 text-md capitalize font-semibold text-[22px] leading-[30px]">
        Staff /
        <span className="text-[#4B465C]">
          {editing === true ? " Edit" : " View"}
        </span>
      </h3>
      <div className="w-full flex flex-col lg:flex-row gap-4 p-6 rounded-md bg-white shadow-gray-card">
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="w-[260px] max-lg:w-full flex flex-col items-center justify-center gap-4">
              <img
                src={staff.data.attributes.image}
                alt="member pic"
                className="w-[100px] h-[100px]"
              />
              <h3 className="text-[22px] leading-[30px] font-semibold text-[#4B465C]">
                {staff.data.attributes.name}
              </h3>
              {editing && (
                <div>
                  <label
                    htmlFor="uploadImage"
                    className="border border-primary-500 text-primary-500 rounded-md py-[10px] px-[20px] cursor-pointer"
                  >
                    Change Profile Image
                  </label>
                  <input
                    type="file"
                    id="uploadImage"
                    name="uploadImage"
                    onChange={(e) => {
                      setStaff({
                        ...staff,
                        data: {
                          ...staff.data,
                          attributes: {
                            ...staff.data.attributes,
                            image: e.target.value,
                          },
                        },
                      });
                    }}
                    hidden
                  />
                </div>
              )}
            </div>
            <div className="flex-1 flex flex-col justify-center gap-4 relative divider">
              <p
                className={`text-[#4b465c63] text-[13px] ${!editing && "mt-4"}`}
              >
                Details
              </p>
              {editing ? (
                <div className="flex flex-col gap-3">
                  <Input
                    title="Username"
                    placeholder="John Doe"
                    type="text"
                    name="username"
                    value={staff.data.attributes.name}
                    onChange={(e) => {
                      setStaff({
                        ...staff,
                        data: {
                          ...staff.data,
                          attributes: {
                            ...staff.data.attributes,
                            name: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <Input
                    title="Email"
                    placeholder="test@test.com"
                    type="text"
                    name="email"
                    value={staff.data.attributes.email}
                    onChange={(e) => {
                      setStaff({
                        ...staff,
                        data: {
                          ...staff.data,
                          attributes: {
                            ...staff.data.attributes,
                            email: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <div className="flex flex-col gap-1">
                    <p className="text-[13px] capitalize font-normal text-[#4B465C]">
                      Status
                    </p>
                    <Select
                      variant="bordered"
                      size="small"
                      placeholder="Status"
                      aria-label="Status"
                      classNames={{
                        trigger:
                          "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        selectStatus(staff.data.id, e.target.value);
                      }}
                      defaultSelectedKeys={staff.data.attributes.status}
                    >
                      <SelectItem key="1" value="1">
                        Active
                      </SelectItem>
                      <SelectItem key="0" value="0">
                        Inactive
                      </SelectItem>
                    </Select>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-[13px] capitalize font-normal text-[#4B465C]">
                      User Role
                    </p>
                    <Select
                      variant="bordered"
                      size="small"
                      placeholder="Select Role"
                      aria-label="Select Role"
                      isLoading={loadingRoles}
                      classNames={{
                        trigger: [
                          "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                          "data-[hover=true]:border-[#DBDADE]",
                          "data-[focus=true]:border-[#DBDADE]",
                          "data-[open=true]:border-[#DBDADE]",
                        ],
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSelectedRole(e.target.value);
                      }}
                      items={roles ? roles : []}
                      value={staff.data.attributes.role}
                      // selectedKeys={staff.data.attributes.role}
                      defaultSelectedKeys={staff.data.attributes.role}
                    >
                      {(item) => (
                        <SelectItem key={item.id} value={item.id}>
                          {item.attribute.name}
                        </SelectItem>
                      )}
                    </Select>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  <p className="text-[15px] font-semibold text-[#4B465C]">
                    Username:
                    <span className="text-[12px] font-normal ml-1">
                      {staff.data.attributes.name}
                    </span>
                  </p>
                  <p className="text-[15px] font-semibold text-[#4B465C]">
                    Email:
                    <span className="text-[12px] font-normal ml-1">
                      {staff.data.attributes.email}
                    </span>
                  </p>
                  <p className="text-[15px] font-semibold text-[#4B465C]">
                    Status:
                    {staff.data.attributes.status === 1 ? (
                      <span className="text-[12px] font-normal bg-success-50 text-success-500 py-[5px] px-[10px] rounded-md ml-1">
                        Active
                      </span>
                    ) : (
                      <span className="text-[12px] font-normal bg-secondary-200 text-secondary-500 py-[5px] px-[10px] rounded-md ml-1">
                        Inactive
                      </span>
                    )}
                  </p>
                  <p className="text-[15px] font-semibold text-[#4B465C]">
                    Role:{" "}
                    <span className="text-[12px] font-normal ml-1">
                      {staff.data.attributes.role}
                    </span>
                  </p>
                </div>
              )}
              <div className="flex gap-4 w-full justify-end">
                {editing ? (
                  <Button
                    size="sm"
                    className="w-[70px] h-[38px] bg-primary-500 text-white text-[15px] font-medium rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
                    onClick={updateStaffHandler}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="w-[70px] h-[38px] bg-primary-500 text-white text-[15px] font-medium rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize"
                    onClick={() => navigate("?edit=true")}
                  >
                    Edit
                  </Button>
                )}
                <Button
                  className="bg-[#EA545529] text-danger-500 text-xs font-medium rounded-md h-[38px]"
                  onClick={() => {
                    navigate("/staff");
                  }}
                >
                  Suspended
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default StaffMember;

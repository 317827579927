import { Divider, Select, SelectItem } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  exportProducts,
  getFilteredProducts,
  getProducts,
  getStores,
  searchProducts,
} from "../../api/AppManagement";
import plus from "../../assets/icons/plus.svg";
import share from "../../assets/icons/screen-share.svg";
import search from "../../assets/icons/search.svg";
import CustomPagination from "../ui/CustomPagination";
import Loader from "../ui/Loader";
import ProductsSingleTable from "./ProductsSingleTable";
import { toast } from "../toast/Toast";

const itemsPerPage = 10;

const ProductsTable = ({ openModal, newProduct }) => {
  const [loadingStores, setLoadingStores] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  let page = parseInt(searchParams.get("page")) || 1;

  const [products, setProducts] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [stores, setStores] = useState();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [date, setDate] = useState("");

  const exportProductsPdf = async () => {
    const { data } = await exportProducts();
    console.log(data);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "products.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const getStoresData = async () => {
      setLoadingStores(true);
      const { data } = await getStores();
      setStores(data.data);
      setLoadingStores(false);
    };
    getStoresData();
  }, []);

  useEffect(() => {
    const getProductsData = async () => {
      setLoading(true);
      const { data } = await getProducts(page);
      setProducts(data);
      setLoading(false);
    };
    if (
      searchTerm.trim().length === 0 &&
      selectedCategory.trim().length === 0 &&
      selectedStatus.trim().length === 0 &&
      selectedStore.trim().length === 0 &&
      date.trim().length === 0
    ) {
      getProductsData();
    }
  }, [page, searchTerm, selectedCategory, selectedStatus, selectedStore, date]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setSearchTerm(searchInput);
    }, 500);
    return () => clearTimeout(timeOut);
  }, [searchInput]);

  useEffect(() => {
    setLoading(true);
    const getSearchProductsData = async () => {
      const { data } = await searchProducts(searchTerm);
      console.log("search", data);
      setProducts(data);
      setLoading(false);
    };
    if (searchTerm.trim().length > 0) {
      getSearchProductsData();
    }
  }, [searchTerm]);

  // useEffect(() => {
  //   try {
  //     if (newProduct) {
  //       if (products.data.length < 10) {
  //         setProducts((prev) => {
  //           return {
  //             ...prev,
  //             data: [newProduct, ...prev.data],
  //             meta: { ...prev.meta, total: prev.meta.total + 1 }, //error here
  //           };
  //         });
  //       }
  //     }
  //   } catch (err) {}
  // }, [newProduct]);

  // useEffect(() => {
  //   try {
  //     if (newProduct && products.data.length < 10) {
  //       setProducts((prev) => {
  //         console.log("kkkkkkkkkkkkkk");
  //         console.log(prev.meta);
  //         return {
  //           ...prev,
  //           data: [newProduct, ...prev.data],
  //           meta: {
  //             ...prev.meta,
  //             total: (prev.meta.total || 0) + 1, // Ensure total is initialized
  //           },
  //         };
  //       });
  //       console.log("just test");
  //       console.log(products);
  //       console.log(page);
  //     }
  //   } catch (err) {
  //     // Handle error here if needed
  //     toast(err);
  //   }
  // }, [newProduct, setProducts]);

  const deleteProduct = (id) => {
    setProducts((prev) => {
      const updatedProducts = products.data.filter(
        (product) => product.id !== id
      );
      return { ...prev, data: updatedProducts };
    });
  };

  const updateProduct = (newData) => {
    const updatedProductIndex = products.data.findIndex(
      (product) => parseInt(product.id) === parseInt(newData.id)
    );
    const updatedProducts = products.data;
    updatedProducts[updatedProductIndex] = newData;
    setProducts((prev) => {
      return { ...prev, data: [...updatedProducts] };
    });
  };

  const filterProducts = async (name, value) => {
    if (value.trim().length > 0) {
      let filter = {};
      filter[name] = value;
      setLoading(true);
      const { data } = await getFilteredProducts(filter, page + 1);
      setProducts(data);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 py-6 bg-white rounded-md shadow-gray-card">
      <div className="flex flex-col gap-4 px-6">
        <h3 className="text-[18px] leading-[24px] font-semibold text-[#38334F]">
          Search filter
        </h3>
        <div className="flex flex-col gap-6 mb-4 lg:flex-row">
          <div className="flex-1 flex items-center border border-[#DBDADE] py-[7px] px-[14px] rounded-md">
            <input
              placeholder="product details"
              className="focus:outline-none flex-1 text-[15px]"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <img src={search} alt="search" className="w-[20px] h-[20px]" />
          </div>
          <div className="flex-1">
            <Select
              variant="bordered"
              size="small"
              placeholder="Select Category"
              aria-label="Select Category"
              classNames={{
                trigger:
                  "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
              }}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                filterProducts("type", e.target.value);
              }}
            >
              <SelectItem key="individual" value="individual">
                Individual
              </SelectItem>
              <SelectItem key="stock" value="stock">
                Stock
              </SelectItem>
            </Select>
          </div>
          <div className="flex-1">
            <Select
              variant="bordered"
              size="small"
              placeholder="Select Store"
              aria-label="Select Store"
              isLoading={loadingStores}
              classNames={{
                trigger: [
                  "rounded-md border border-[#DBDADE] py-[7px] px-[14px]",
                  "data-[hover=true]:border-[#DBDADE]",
                  "data-[focus=true]:border-[#DBDADE]",
                  "data-[open=true]:border-[#DBDADE]",
                ],
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedStore(e.target.value);
                filterProducts("vendor_id", e.target.value);
              }}
              items={stores ? stores : []}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.attributes.name}
                </SelectItem>
              )}
            </Select>
          </div>
          <div className="flex-1">
            <Select
              variant="bordered"
              size="small"
              placeholder="Product Status"
              aria-label="Product Status"
              classNames={{
                trigger:
                  "dropdown data-[hover=true]:border-[#DBDADE] data-[focus=true]:border-[#DBDADE] data-[open=true]:border-[#DBDADE]",
              }}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                filterProducts("status", e.target.value);
              }}
            >
              <SelectItem key="1" value="1">
                Active
              </SelectItem>
              <SelectItem key="0" value="0">
                Inactive
              </SelectItem>
            </Select>
          </div>
          <div className="flex-1">
            <input
              type="date"
              className="w-full h-[40px] border border-[#DBDADE] py-[7px] px-[14px] rounded-md focus:outline-none"
              onChange={(e) => {
                setDate(e.target.value);
                var parts = e.target.value.split("-");
                if (parts.length === 3) {
                  filterProducts("created_at", e.target.value);
                }
              }}
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-4 px-6 sm:flex-row">
        <button
          className="flex gap-2 px-[20px] py-[10px] items-center max-sm:justify-center rounded-md shadow-gray-sm bg-primary-500 text-white"
          onClick={openModal}
        >
          <img src={plus} alt="add" />
          <p>Add New Product</p>
        </button>
        <button
          className="flex justify-center items-center gap-[6px] px-[20px] py-[10px] rounded-md text-[#A8AAAE] bg-[#a8aaae34]"
          onClick={exportProductsPdf}
        >
          <img src={share} alt="share" />
          <p>Export</p>
        </button>
      </div>
      <div>
        {!loading && products && (
          <ProductsSingleTable
            data={products.data}
            onDeleteProduct={deleteProduct}
            onUpdateProduct={updateProduct}
          />
        )}
        {loading && <Loader />}
      </div>
      {!loading && products?.meta !== undefined && (
        <div className="flex flex-col items-center justify-between gap-4 px-6 lg:flex-row">
          <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
            Showing {(page - 1) * itemsPerPage + 1} to{" "}
            {(page - 1) * itemsPerPage + products.data.length} of{" "}
            {products.meta.total} entries
          </p>
          <div className="flex justify-center w-full lg:justify-end">
            <CustomPagination
              page={page}
              count={Math.ceil(products.meta.total / itemsPerPage)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsTable;

import { Button } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import CustomPagination from "../../ui/CustomPagination";
import Modal from "../../ui/Modal";
import PlusBtn from "../../ui/icons/PlusBtn";
import AddSubUsersModal from "../AddSubUsersModal";
import AccountTable from "./AccountTable";
import { getSubUsers } from "../../../api/AppManagement";
import { useParams, useSearchParams } from "react-router-dom";
import Loader from "../../ui/Loader";

const itemsPerPage = 10;

const Account = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const { id } = useParams();

  const [searchParams] = useSearchParams();
  let page = parseInt(searchParams.get("page")) || 1;

  const [loading, setLoading] = useState(true);
  //
  const [subUsers, setSubUsers] = useState();
  const [newSubUser, setNewSubUser] = useState();

  const openModal = () => {
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    const subUser = async () => {
      const { data } = await getSubUsers(id);
      setLoading(false);
      setSubUsers(data);
    };
    subUser();
  }, [id, update]);

  const updateSubUser = (newData) => {
    const updatedSubUserIndex = subUsers.data.findIndex(
      (subUser) => parseInt(subUser.id) === parseInt(newData.id)
    );
    const updatedSubUser = subUsers.data;
    updatedSubUser[updatedSubUserIndex] = newData;
    setSubUsers((prev) => {
      return { ...prev, data: [...updatedSubUser] };
    });
  };

  useEffect(() => {
    if (newSubUser) {
      if (subUsers.data.length < 10) {
        setSubUsers((prev) => {
          return {
            ...prev,
            data: [newSubUser, ...prev.data],
          };
        });
      }
    }
  }, [newSubUser]);

  const deleteSubUser = (id) => {
    setSubUsers((prev) => {
      const updatedStaff = prev.data.filter((staff) => staff.id !== id);
      return {
        ...prev,
        data: updatedStaff,
      };
    });
  };

  return (
    <div className="flex flex-col w-full gap-4 py-6 bg-white rounded-md shadow-gray-card">
      <header className="flex items-center justify-between p-6">
        <h3 className="text-[#4B465C] text-lg capitalize font-semibold">
          User's Projects List
        </h3>
        <Button
          startContent={<PlusBtn />}
          className="bg-primary-500 text-white text-[13px] py-[6px] px-[14px] h-[28px] rounded-[4px] shadow-[0_2_4_0px]"
          onClick={openModal}
        >
          Add User
        </Button>
      </header>

      {!loading && subUsers && (
        <AccountTable
          subUsers={subUsers.data}
          onUpdateSubUser={updateSubUser}
          onDeleteSubUser={deleteSubUser}
          storeId={id}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      {loading && <Loader />}

      {!loading && (
        <div className="flex flex-col items-center justify-between gap-4 px-6 lg:flex-row">
          <p className="w-full flex justify-center lg:justify-start text-[13px] text-[#B8B0CE]">
            Showing {(page - 1) * itemsPerPage + 1} to{" "}
            {(page - 1) * itemsPerPage + subUsers.data.length} of 10 entries
          </p>
          <div className="flex justify-center w-full lg:justify-end">
            <CustomPagination />
          </div>
        </div>
      )}
      <Modal isOpen={isModelOpen} onClose={closeModal} title="Add Sub users">
        <AddSubUsersModal
          closeModal={closeModal}
          setNewSubUser={setNewSubUser}
          storeId={id}
        />
      </Modal>
    </div>
  );
};

export default Account;

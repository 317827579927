import { Divider } from "@nextui-org/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import close from "../../assets/icons/close.svg";

const ModalReport = ({ isOpen, onClose, children }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("opened-model");
    } else {
      document.body.classList.remove("opened-model");
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? "0%" : "-100%" }}
            exit={{ opacity: 0, y: "-100%" }}
            transition={{ duration: 0.3 }}
            className="bg-white p-[65px] shadow-[0px_5px_20px_0px_rgba(75, 70, 92, 0.40)] rounded-md w-[720px] relative max-md:p-[20px] max-md:m-[20px]"
            onClick={handleModalClick}
          >
            <div className="flex justify-between items-center flex-col gap-4 ">
              <h2 className="text-[26px] font-semibold leading-9 text-[#4B465C] capitalize">
                Export PDF
              </h2>
              <button
                onClick={onClose}
                className="absolute top-0 right-0 translate-x-[50%] translate-y-[-50%] p-[6px] rounded-md flex items-center justify-center bg-white cursor-pointer shadow-gray-sm"
              >
                <img src={close} alt="close" className="w-4 h-4" />
              </button>
              <input
                placeholder="Name Report"
                value={"Name Report"}
                className="text-[15px] font-semibold leading-[21px] text-[#282B3E] capitalize p-2 text-center"
              />
              <Divider />

              {children}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ModalReport;

import React from 'react';

const LogoutIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            className='min-w-[22px] min-h-[22px]'
        >
            <path
                d='M12.8333 7.33329V5.49996C12.8333 4.48744 12.0125 3.66663 11 3.66663H4.58333C3.57081 3.66663 2.75 4.48744 2.75 5.49996V16.5C2.75 17.5125 3.57081 18.3333 4.58333 18.3333H11C12.0125 18.3333 12.8333 17.5125 12.8333 16.5V14.6666'
                stroke='#4B465C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
                />
            <path
                d='M12.8333 7.33329V5.49996C12.8333 4.48744 12.0125 3.66663 11 3.66663H4.58333C3.57081 3.66663 2.75 4.48744 2.75 5.49996V16.5C2.75 17.5125 3.57081 18.3333 4.58333 18.3333H11C12.0125 18.3333 12.8333 17.5125 12.8333 16.5V14.6666'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.41602 10.25C6.0018 10.25 5.66602 10.5858 5.66602 11C5.66602 11.4142 6.0018 11.75 6.41602 11.75V10.25ZM19.2493 11V11.75C19.5527 11.75 19.8262 11.5673 19.9423 11.287C20.0583 11.0068 19.9942 10.6842 19.7797 10.4697L19.2493 11ZM17.0297 7.71967C16.7368 7.42678 16.2619 7.42678 15.969 7.71967C15.6761 8.01256 15.6761 8.48744 15.969 8.78033L17.0297 7.71967ZM15.969 13.2197C15.6761 13.5126 15.6761 13.9874 15.969 14.2803C16.2619 14.5732 16.7368 14.5732 17.0297 14.2803L15.969 13.2197ZM19.7797 11.5303C20.0726 11.2374 20.0726 10.7626 19.7797 10.4697C19.4868 10.1768 19.0119 10.1768 18.719 10.4697L19.7797 11.5303ZM6.41602 11.75H19.2493V10.25H6.41602V11.75ZM19.7797 10.4697L17.0297 7.71967L15.969 8.78033L18.719 11.5303L19.7797 10.4697ZM17.0297 14.2803L19.7797 11.5303L18.719 10.4697L15.969 13.2197L17.0297 14.2803Z'
                fill='#4B465C'
            />
            <path
                d='M6.41602 10.25C6.0018 10.25 5.66602 10.5858 5.66602 11C5.66602 11.4142 6.0018 11.75 6.41602 11.75V10.25ZM19.2493 11V11.75C19.5527 11.75 19.8262 11.5673 19.9423 11.287C20.0583 11.0068 19.9942 10.6842 19.7797 10.4697L19.2493 11ZM17.0297 7.71967C16.7368 7.42678 16.2619 7.42678 15.969 7.71967C15.6761 8.01256 15.6761 8.48744 15.969 8.78033L17.0297 7.71967ZM15.969 13.2197C15.6761 13.5126 15.6761 13.9874 15.969 14.2803C16.2619 14.5732 16.7368 14.5732 17.0297 14.2803L15.969 13.2197ZM19.7797 11.5303C20.0726 11.2374 20.0726 10.7626 19.7797 10.4697C19.4868 10.1768 19.0119 10.1768 18.719 10.4697L19.7797 11.5303ZM6.41602 11.75H19.2493V10.25H6.41602V11.75ZM19.7797 10.4697L17.0297 7.71967L15.969 8.78033L18.719 11.5303L19.7797 10.4697ZM17.0297 14.2803L19.7797 11.5303L18.719 10.4697L15.969 13.2197L17.0297 14.2803Z'
                fill='white'
                fillOpacity='0.2'
            />
        </svg>
    );
};

export default LogoutIcon;

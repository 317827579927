import { toast } from "../components/toast/Toast";
import { API } from "./api";

export const getProducts = (page) => {
  return API.get("products/get-all", { params: { page: page } });
};

export const getFilteredProducts = (filter, page) => {
  return API.get(`products/product-filter`, {
    params: {
      page: page,
      type: filter.type,
      status: filter.status,
      created_at: filter.created_at,
    },
  });
};

export const getProductsNumber = () => {
  return API.get("products/total");
};

export const addProduct = (product) => {
  const formData = new FormData();
  formData.append("vendor_id", product.vendor_id);
  formData.append("title", product.title);
  formData.append("standard", parseInt(product.standard));
  formData.append("weight", product.weight);
  formData.append("amount", product.amount);
  formData.append("make_up", product.make_up);
  formData.append("type", product.type);
  formData.append("price", product.price);
  formData.append("product_type", product.product_type);

  return API.post("products", formData);
};

export const deleteProduct = (id) => {
  return API.delete(`products/${id}`);
};

export const updateProduct = (id, product) => {
  return API.put(`products/${id}`, product);
};

export const getSingleProduct = (id) => {
  return API.get(`products/${id}`);
};

export const searchProducts = (query) => {
  const formData = new FormData();
  formData.append("title", query);
  return API.post("products/search-product", formData);
};

export const exportProducts = () => {
  return API.get("list-pdf", {
    responseType: "blob",
  });
};

export const changeStatusProduct = (id, status) => {
  return API.put(
    `products/change-status-product/${id}`,
    {},
    {
      params: { status: status },
    }
  );
};

export const getStores = () => {
  return API.get("vendors/get-all");
};

export const getFilteredStores = (filter, page) => {
  return API.get(`vendors/filter`, {
    params: {
      page: page,
      plan_id: filter.plan_id,
      paid_status: filter.paid_status,
      created_at: filter.created_at,
    },
  });
};

export const exportStores = () => {
  return API.get("vendor-list", {
    responseType: "blob",
  });
};

export const searchStores = (query) => {
  const formData = new FormData();
  formData.append("name", query);
  return API.post("vendors/search", formData);
};

export const addStore = (store) => {
  const formData = new FormData();
  formData.append("name", store.name);
  formData.append("email", store.email);
  formData.append("address", store.address);
  formData.append("city", store.city);
  formData.append("contact", store.contact);
  formData.append("paid_status", store["paid_status"]);
  formData.append("plan_id", store["plan_select"]);
  formData.append("image", store.image);
  return API.post("vendors", formData);
};

export const deleteStore = (id) => {
  return API.delete(`vendors/${id}`);
};

export const updateStore = (id, store) => {
  const formData = new FormData();

  formData.append("name", store.name);
  formData.append("email", store.email);
  formData.append("address", store.address);
  formData.append("city", store.city);
  formData.append("contact", store.contact);
  formData.append("paid_status", store["paid_status"]);
  formData.append("plan_id", store["plan_id"]); //there was error here => khaled handled it
  formData.append("image", store.image);

  return API.post(`vendors/${id}`, formData);
};

export const changeStatusStore = (id, status) => {
  try {
    return API.put(
      `vendors/change-status-vendor/${id}`,
      {},
      {
        params: { status: status },
      }
    );
  } catch (error) {
    toast(error);
  }
};

export const getSingleStore = (id) => {
  return API.get(`vendors/${id}`);
};

export const getStoresNumber = () => {
  return API.get("vendors/total-user");
};

export const getReasons = () => {
  return API.get("reasons");
};

export const addReason = (reason) => {
  const formData = new FormData();
  formData.append("vendor_id", reason.vendor_id);
  formData.append("name", reason.name);
  formData.append("type", reason.type);
  return API.post("reasons", formData);
};

export const updateReason = (id, reason) => {
  return API.put(`reasons/${id}`, reason);
};
export const deleteReason = (id) => {
  return API.delete(`reasons/${id}`);
};

export const getFilteredReasons = (filter) => {
  return API.get(`reasons/filter`, {
    params: {
      status: filter.status,
      type: filter.type,
      vendor_id: filter.vendor_id,
    },
  });
};

export const searchReasons = (query) => {
  const formData = new FormData();
  formData.append("name", query);
  console.log(formData);
  return API.post("reasons/search", formData);
};

export const changeStatusReasons = (id, status) => {
  return API.put(
    `reasons/change-status/${id}`,
    {},
    {
      params: { status: status },
    }
  );
};

export const getPlans = () => {
  return API.get("plans/list-all");
};

export const getStoreRole = () => {
  return API.get("types");
};

export const addStoreRole = (role) => {
  const formData = new FormData();
  formData.append("type", role.type);
  role.plan_ids.map((planId, index) => {
    formData.append(`plan_ids[${index}]`, parseInt(planId));
  });

  return API.post("types", formData);
};

export const deleteStoreRole = (id) => {
  return API.delete(`types/${id}`);
};

export const updateStoreRole = (id, role) => {
  const formData = new FormData();
  formData.append("type", role.type);
  role.plan_ids.forEach((planId, index) => {
    formData.append(`plan_ids[${index}]`, parseInt(planId));
  });
};

export const getSubUsers = (id) => {
  return API.get(`subusers/${id}`);
};

export const deleteSubUser = (id) => {
  return API.delete(`subusers/${id}`);
};

export const addSubUser = (subUser) => {
  const formData = new FormData();
  formData.append("name", subUser.name);
  formData.append("email", subUser.email);
  formData.append("password", subUser.password);
  formData.append("role", subUser.role);
  formData.append("phone", subUser.phone);
  formData.append("type_id", subUser.type_id);
  formData.append("vendor_id", subUser.vendor_id);
  console.log("test subUser");
  return API.post("subusers", formData);
};

export const updateSubUser = (id, subUser) => {
  return API.post(`subusers/${id}`, subUser);
};

export const getBilling = () => {
  return API.get("bills/list-all-filter");
};

export const deleteBilling = (id) => {
  return API.delete(`payments/${id}`);
};

export const getPlan = () => {
  return API.get("plans/list-all");
};

export const getDashboardStatistic = () => {
  return API.get("dashboard/total");
};

export const getWallets = (id) => {
  return API.get(`dashboard/payment-method/${id}`);
};

export const getGraphData = () => {
  return API.get(`dashboard/enrollment/${new Date().getFullYear()}`);
};

export const getTotalIncomeYear = () => {
  return API.get(`dashboard/total-income-billing/${new Date().getFullYear()}`);
};

export const getPaymentPercentage = (id) => {
  return API.get(`dashboard/paymentMethodPresentage/${id}`);
};

export const getExportSalesMonth = (month) => {
  return API.get(`sales/report-sale-by-month`, {
    params: {
      month: month,
    },
  });
};

export const getExportSalesYear = (year) => {
  return API.get(`sales/report-sale-by-year`, {
    params: {
      year: year,
    },
  });
};

export const getExportSalesDate = (start_date, end_date) => {
  return API.get(`sales/report-sale-by-date`, {
    params: {
      start_date: start_date,
      end_date: end_date,
    },
  });
};

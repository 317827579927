import React, { useState } from "react";
import debts from "../../assets/icons/debts.svg";
import merchantReport from "../../assets/icons/merchantReport.svg";
import purchases from "../../assets/icons/purchases.svg";
import reportMinus from "../../assets/icons/reportMinus.svg";
import reportPlus from "../../assets/icons/reportPlus.svg";
import sales from "../../assets/icons/sales.svg";
import CustomModalContent from "./CustomModalContent";
import ModalReport from "./ModalReport";
import MonthlyModalContent from "./MonthlyModalContent";
import ReportCard from "./ReportCard";
import YearlyModalContent from "./YearlyModalContent";

const CardDetail = [
  {
    id: 1,
    icon: sales,
    title: "Export Sales Report",
    subtitle:
      "For Any Durations You Can Just Select The Duration And The Store Name",
  },
  {
    id: 2,
    icon: purchases,
    title: "export purchases report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
  {
    id: 3,
    icon: reportPlus,
    title: "export sales and purchases report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
  {
    id: 4,
    icon: reportMinus,
    title: "export expenses report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
  {
    id: 5,
    icon: debts,
    title: "export Debts report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
  {
    id: 6,
    icon: merchantReport,
    title: "export Merchant account statement report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
  {
    id: 7,
    icon: merchantReport,
    title: "export profit report",
    subtitle:
      "for any durations you can just select the duration and the store name",
  },
];

const StoreReports = () => {
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isMonthModalOpen, setIsMonthModalOpen] = useState(false);
  const [isYearlyModalOpen, setIsYearlyModalOpen] = useState(false);

  const onCustomOpen = () => {
    setIsCustomModalOpen(true);
  };
  const onMonthOpen = () => {
    setIsMonthModalOpen(true);
  };
  const onYearlyOpen = () => {
    setIsYearlyModalOpen(true);
  };
  const onCustomOpenChange = () => {
    setIsCustomModalOpen(false);
  };
  const onMonthOpenChange = () => {
    setIsMonthModalOpen(false);
  };
  const onYearlyOpenChange = () => {
    setIsYearlyModalOpen(false);
  };

  return (
    <section className="flex flex-col pt-[26px]">
      <h3 className="page-heading">Store Reports</h3>

      <div className="w-full flex justify-center items-center flex-col gap-[26px] my-[26px]">
        {CardDetail.map((item) => (
          <ReportCard
            key={item.id}
            icon={item.icon}
            title={item.title}
            subtitle={item.subtitle}
            onCustomOpen={onCustomOpen}
            onMonthOpen={onMonthOpen}
            onYearOpen={onYearlyOpen}
          />
        ))}
      </div>

      <ModalReport isOpen={isCustomModalOpen} onClose={onCustomOpenChange}>
        <CustomModalContent
          onClose={onCustomOpenChange}
          title="custom select"
        />
      </ModalReport>

      <ModalReport isOpen={isMonthModalOpen} onClose={onMonthOpenChange}>
        <MonthlyModalContent onClose={onMonthOpenChange} title="month select" />
      </ModalReport>

      <ModalReport isOpen={isYearlyModalOpen} onClose={onYearlyOpenChange}>
        <YearlyModalContent
          onClose={onYearlyOpenChange}
          title="custom select"
        />
      </ModalReport>
    </section>
  );
};

export default StoreReports;

import { Button } from "@nextui-org/button";
import React, { useState } from "react";
import BorderedCheckbox from "../ui/BorderedCheckbox";
import Input from "../ui/Input";
import { updateStoreRole } from "../../api/AppManagement";
import { toast } from "../toast/Toast";

const EditPermissionsModal = ({ closeModal, role, onUpdateRole }) => {
  const [type, setType] = useState(role.attributes.type);
  const [selectedRoles, setSelectedRoles] = useState(role.plans);

  const updateRoleHandler = () => {
    console.log(role);
    try {
      const { data } = updateStoreRole(role.id, {
        type,
        plan_ids: selectedRoles,
      });
      // onUpdateRole(data.data);
      console.log(data);
      closeModal();
    } catch (error) {
      toast(error);
    }
  };

  return (
    <div className="flex flex-col gap-[26px]">
      <Input
        title="Role Name"
        type="text"
        name="name"
        placeholder="Sales"
        value={type}
        onChange={(e) => setType(e.target.value)}
      />
      <div className="flex flex-col gap-3 px-[18px]">
        {selectedRoles.map((role) => (
          <BorderedCheckbox
            key={role.value}
            label={role.label}
            value={role.value}
            checked={selectedRoles.includes(role.value)}
            // onChange={() => toggleRoleSelection(role.value)}
            // onChangeHandler={toggleRoleSelection}
          />
        ))}
      </div>
      <div className="flex items-start gap-4 mt-[10px]">
        <Button
          size="sm"
          className="w-max bg-primary-500 text-white text-[15px] font-medium tracking-[0.43px] rounded-md py-[10px] px-[20px] shadow-gray-sm capitalize"
          onClick={updateRoleHandler}
        >
          Save
        </Button>

        <Button
          size="sm"
          className="w-max bg-secondary-200 text-secondary-500 text-[15px] font-medium tracking-[0.43px] rounded-md py-[10px] px-[20px] shadow-gray-sm capitalize"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditPermissionsModal;

import React from 'react';

const StaffIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
        >
            <ellipse
                cx='8.24967'
                cy='6.41667'
                rx='3.66667'
                ry='3.66667'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='8.24967'
                cy='6.41667'
                rx='3.66667'
                ry='3.66667'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.75 19.25V17.4167C2.75 15.3916 4.39162 13.75 6.41667 13.75H10.0833C12.1084 13.75 13.75 15.3916 13.75 17.4167V19.25'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.75 19.25V17.4167C2.75 15.3916 4.39162 13.75 6.41667 13.75H10.0833C12.1084 13.75 13.75 15.3916 13.75 17.4167V19.25'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.667 9.3335C14.2528 9.3335 13.917 9.66928 13.917 10.0835C13.917 10.4977 14.2528 10.8335 14.667 10.8335V9.3335ZM20.167 10.8335C20.5812 10.8335 20.917 10.4977 20.917 10.0835C20.917 9.66928 20.5812 9.3335 20.167 9.3335V10.8335ZM18.167 7.3335C18.167 6.91928 17.8312 6.5835 17.417 6.5835C17.0028 6.5835 16.667 6.91928 16.667 7.3335H18.167ZM16.667 12.8335C16.667 13.2477 17.0028 13.5835 17.417 13.5835C17.8312 13.5835 18.167 13.2477 18.167 12.8335H16.667ZM14.667 10.8335H20.167V9.3335H14.667V10.8335ZM16.667 7.3335V12.8335H18.167V7.3335H16.667Z'
                fill={color}
            />
            <path
                d='M14.667 9.3335C14.2528 9.3335 13.917 9.66928 13.917 10.0835C13.917 10.4977 14.2528 10.8335 14.667 10.8335V9.3335ZM20.167 10.8335C20.5812 10.8335 20.917 10.4977 20.917 10.0835C20.917 9.66928 20.5812 9.3335 20.167 9.3335V10.8335ZM18.167 7.3335C18.167 6.91928 17.8312 6.5835 17.417 6.5835C17.0028 6.5835 16.667 6.91928 16.667 7.3335H18.167ZM16.667 12.8335C16.667 13.2477 17.0028 13.5835 17.417 13.5835C17.8312 13.5835 18.167 13.2477 18.167 12.8335H16.667ZM14.667 10.8335H20.167V9.3335H14.667V10.8335ZM16.667 7.3335V12.8335H18.167V7.3335H16.667Z'
                fill={color}
            />
        </svg>
    );
};

export default StaffIcon;

import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import close from "../../assets/icons/close.svg";

const Modal = ({ isOpen, onClose, children, title }) => {
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("opened-model");
    } else {
      document.body.classList.remove("opened-model");
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: isOpen ? 1 : 0, x: isOpen ? 0 : "100%" }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3 }}
            className="bg-white p-6 shadow-[0px_4px_18px_0px_rgba(75, 70, 92, 0.10)] right-0 w-[400px] h-full absolute overflow-auto scrollbar max-sm:w-full"
            onClick={handleModalClick}
          >
            <div className="flex justify-between items-center mb-[26px]">
              <h2 className="text-lg font-semibold text-[#4B465C] capitalize">
                {title}
              </h2>
              <button
                onClick={onClose}
                className="p-[7px] rounded-md flex items-center justify-center bg-secondary-300 cursor-pointer"
              >
                <img src={close} alt="close" className="w-[18px] h-[18px]" />
              </button>
            </div>
            {children}
          </motion.div>
        </div>
      )}{" "}
    </AnimatePresence>
  );
};

export default Modal;

import React from "react";
import paymentPlus from "../../assets/icons/paymentPlus.svg";
import { CircularProgress } from "@nextui-org/react";

const data = [
  { label: "Vodafone Cash", percentage: 54.4 },
  { label: "Vodafone Cash", percentage: 14.6 },
  { label: "Vodafone Cash", percentage: 6.1 },
];

const PaymentMethods = () => {
  return (
    <div className="flex flex-col p-6 rounded-md shadow-gray-card bg-white w-[347px] max-lg:w-full">
      <div className="flex items-start justify-between">
        <div className="flex flex-col items-start">
          <h3 className="text-[18px] font-semibold leading-6 text-[#4B465C] capitalize">
            payment methods
          </h3>
          <p className="text-[13px] leading-5 text-[#a5a2ad] capitalize">
            view the % in every wallet
          </p>
        </div>
        <img src={paymentPlus} alt="payment" className="cursor-pointer" />
      </div>

      <div className="flex flex-col gap-8 mt-6">
        {data.map((item, index) => (
          <div key={index} className="flex items-center justify-between">
            <p className="text-[15px] font-semibold leading-[21px] capitalize text-[#4b465c]">
              {item.label}
            </p>
            <div className="flex items-center gap-4">
              <p className="text-[15px] font-semibold leading-[21px] capitalize text-[#6f6b7d]">
                {item.percentage}%
              </p>
              <CircularProgress
                aria-label="progress"
                value={item.percentage}
                classNames={{
                  indicator: "stroke-[#a5a3ae]",
                  track: "stroke-[#DBDADE]",
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethods;

import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import { getAppPages, updatePackage } from '../../api/DashboardManagement';
import BorderedCheckbox from '../ui/BorderedCheckbox';
import Input from '../ui/Input';

const EditPackageModal = ({
  closeModal,
  packageData,
  toggleEditing,
  isEditing,
  onUpdatePackage,
}) => {
  const [name, setName] = useState(packageData.attributes.name);
  const [numberUsers, setNumberUsers] = useState(
    packageData.attributes['num_user']
  );
  const [price, setPrice] = useState(packageData.attributes.price);
  const [description, setDescription] = useState(
    packageData.attributes.description
  );
  const [packagePages, setPackagePages] = useState(
    packageData.pages.map((page) => page.id)
  );

  const [pages, setPages] = useState();

  useEffect(() => {
    const getAppPagesData = async () => {
      const { data } = await getAppPages();
      console.log(data);
      setPages(data.data);
    };
    getAppPagesData();
  }, []);

  const updatePackageHandler = async () => {
    const { data } = await updatePackage(packageData.id, {
      name,
      num_user: parseInt(numberUsers),
      price: parseInt(price),
      description,
      page_ids: packagePages,
    });
    console.log(data);
    onUpdatePackage(data.data);
    closeModal();
  };
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;

    // Update the state based on checkbox change
    if (checked) {
      setPackagePages((prevState) => [...prevState, value]);
    } else {
      setPackagePages((prevState) =>
        prevState.filter((item) => item !== value)
      );
    }
    console.log(packagePages);
  };
  return (
    <div className='flex flex-col gap-4'>
      <Input
        title='Package Name'
        type='text'
        name='package'
        placeholder='Basic Package'
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={!isEditing}
      />
      <Input
        title='Number Users'
        type='number'
        name='users_number'
        placeholder='10'
        value={numberUsers}
        onChange={(e) => setNumberUsers(e.target.value)}
        disabled={!isEditing}
      />
      <div className='flex flex-col gap-1'>
        <p className='text-[13px] capitalize font-normal text-[#4B465C]'>
          Description
        </p>
        <textarea
          rows={3}
          placeholder='...'
          className='w-full flex text-md border border-[#DBDADE] resize-x-none rounded-md text-black py-[7px] px-[14px] focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          disabled={!isEditing}
        />
      </div>
      <div className='flex flex-col gap-3'>
        {pages?.map((page) => (
          <BorderedCheckbox
            key={page.id}
            id={page.id}
            label={page.attribute.title}
            value={page.id}
            onChangeHandler={handleCheckboxChange}
            isDisabled={!isEditing}
            isChecked={() => {
              const index = packagePages.findIndex(
                (packagePage) => packagePage === page.id
              );
              if (index === -1) {
                return false;
              } else {
                return true;
              }
            }}
          />
        ))}
      </div>
      <Input
        title='Package Price'
        type='number'
        name='package_price'
        placeholder='1500'
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        disabled={!isEditing}
      />

      <div className='flex items-start gap-4 mt-[10px]'>
        {isEditing ? (
          <Button
            size='sm'
            className='w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize'
            onClick={updatePackageHandler}
          >
            Save
          </Button>
        ) : (
          <Button
            size='sm'
            className='w-max bg-primary-500 text-white text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize'
            onClick={toggleEditing}
          >
            Edit
          </Button>
        )}

        <Button
          size='sm'
          className='w-max bg-secondary-200 text-secondary-500 text-sm rounded-md py-[10px] px-[20px] shadow-[0px_2px_4px_0px_rgba(165, 163, 174, 0.30)] capitalize'
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditPackageModal;

import axios from "axios";

export const API = axios.create({
  baseURL: "https://dafter.softa-solutions.com/api/",
});

// const refreshToken = async () => {
//   try {
//     const response = await API.post(
//       "auth/refresh-token",
//       {},
//       {
//         headers: {
//         },
//       }
//     );
//     const newToken = response.data.token;
//     return newToken;
//   } catch (error) {
//     console.error("Error refreshing token:", error);
//     throw error;
//   }
// };

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// if (localStorage.getItem('remember') === 'true') {
//     let retry = 0
//     API.interceptors.response.use(
//         (response) => {
//             return response;
//         },
//         async (error) => {
//             const originalRequest = error.config;
//             if (error.response.status && error.response.status === 401 && !originalRequest._retry && retry === 0) {
//                 retry += 1
//                 originalRequest._retry = true;
//                 try {
//                     const newToken = await refreshToken();
//                     API.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
//                     originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
//                     return API(originalRequest);
//                 } catch (error) {
//                     // Handle error, maybe redirect to login page
//                     console.error('Error refreshing token:', error);
//                     throw error;
//                 }
//             }
//             return Promise.reject(error);
//         }
//     );
// }
